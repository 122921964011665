import styled from "styled-components";

export const Notice = styled.div`
  hr.hr{
    margin: 60px 0 0;
    
    ${({ theme }) => theme.media.mobile`
      margin-top: 20px;
    `}
  }
  .notice-content{
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.75px;
    color: ${({ theme }) => theme.colorGray_333};
    white-space: pre-line;
    padding-right: 52px;
    
    ${({ theme }) => theme.media.mobile`
      padding-right: 0;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.75px;

      p, span {
        font-size: 15px !important;
      }
    `}
  }
  .accordion {
    border-bottom: 1px solid ${({ theme }) => theme.colorGray_e1e1e1};
  }
`