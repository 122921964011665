import qs from 'qs';
import { $api } from '../utils/api';
import { LastAwardInfo } from './models';

class AwardService {
  async getlastAwardInfo() { // (최신회차) 수상자
    return $api.getAsList('/baeksang/winner/last-award-info', LastAwardInfo)
  }
  
  async getAwardInfo(episodeId:string) { // 해당 회차 수상자
    let url = '/baeksang/winner/award-info'
    if (episodeId) {
      url = `${url}?${qs.stringify({episodeId})}`
    }
    return $api.getAsList(url, LastAwardInfo)
  }
}

export const awardService = new AwardService();
