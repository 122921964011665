import * as S from './NomineeItem.styled'
import ImageRatio from '../../stories/Images/ImageRatio'

export type TypeNomineeItem = {
  image: string,
  title: string,
  maker: string
}

export default function NomineeItem ({
  data
}:{
  data:TypeNomineeItem
}) {
  return (
    <S.NomineeItem>
      <ImageRatio src={data.image} width={386} ratio={{x:5,y:7}} hoverEffect={true}/>
      <p className='title'>{data.title}</p>
      <p className='maker'>{data.maker}</p>
    </S.NomineeItem>
  )
}