import styled from "styled-components";

export const VideoItem = styled.div`
  img{
    cursor: pointer;
  }
  .video-title{
    margin-top: 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.75px;
    
    ${({ theme }) => theme.media.mobile`
      margin-top: 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: -0.75px;
    `}
  }
`