import qs from 'qs';
import { $api } from '../utils/api';
import { Video } from './models';

class VideoService {
	async getLatestVideos(count: number = 6) {
		return $api.getAsList(
			`/baeksang/vod/getLatestVodList`,
			Video,
			{},
			process.env.REACT_APP_apiUrl,
		);
	}
 
	async getVideos(year: number, page?: number, size?: number) {
		return $api.get(
			`/baeksang/vod/replay/${year}?${qs.stringify({
				page,
				size,
			})}`,
			Video,
			null,
			process.env.REACT_APP_apiUrl,
		);
	}
}

export const videoService = new VideoService();