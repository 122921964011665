import { BaseDto } from './base';

export class SectorInfo extends BaseDto<SectorInfo> {
  episodeId = '';
  sectorId = '';
  sectortitle = '';
  sectorTitle = '';
  list = [
    {
      awardId: '',
      awardName: ''
    }
  ]

	constructor(partial?: Partial<SectorInfo>) {
		super();

		Object.assign(this, partial);
	}
}

export class AwardInfo extends BaseDto<SectorInfo> {
  candidateId = '';
  awardId = '';
  sortNo = 0;
  image = '';
  imageAlt = '';
  titlePc = '';
  titlePc2 = '';
  titleMo = '';
  titleMo2 = '';

	constructor(partial?: Partial<SectorInfo>) {
		super();

		Object.assign(this, partial);
	}
}

