import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import AwardsItem, { TypeAwardsItem } from "../components/awards/AwardsItem"
import useColor from '../components/useColor'
import WinnersHistoryTable from '../components/winners/WinnersHistoryTable'
import { awardService, episodeService } from '../services'
import { EpisodeInfo } from "../services/models"
import { fadeIn } from '../stories/Header/Header'
import SlideTab from '../stories/Tab/SlideTab'
import { TabProps } from '../stories/Tab/Tab'
import * as S from './Winners.styled'

type TypeAwardList = {
  sectorTitle: string,
  list: TypeAwardsItem[]
}
type TypeWinnersTable = {
  title: string,
  section: string,
  list: {
    award: string,
    target: string[],
    span?: number
  }[]
}

export default function Winners() {
  const { episodeInfo } = useOutletContext<{
    episodeInfo: EpisodeInfo
  }>()

  const setColor = useColor;

  const [tabData, setTabData] = useState<TabProps[]>([])
  const [activeTab, setActiveTab] = useState<string>('2024')
  const [winnersTitle, setWinnersTitle] = useState<string>('')
	const [historyTable, setHistoryTable] = useState<TypeWinnersTable[]>();
  const [episode, setEpisode] = useState<EpisodeInfo>()
  const [lastYear, setLastYear] = useState<string>()

  // 대상 리스트
  const [winnerList, setWinnerList] = useState<TypeAwardsItem[]>()
  // 수상 리스트
  const [awardList, setAwardList] = useState<TypeAwardList[]>()

  const init = async () => {
    const resEpisode = await episodeService.getEpisodes()
    const resAward = await awardService.getAwardInfo(resEpisode[1].episodeId)

    const resWinnerList = resAward.filter((item)=>item.isExistWinner)
    const resAwardList = resAward;
    
    let winnerListTemp:TypeAwardsItem[] = []
    let awardListTemp:TypeAwardList[] = []

    resWinnerList.forEach((item)=>{
      winnerListTemp.push({
        image: item.winner.image,
        target: `${item.sectorTitle} 대상`,
        maker: '',
        type: 'grand',
        prize: item.winner.winnerTitlePc,
        color: setColor(item.sectorTitle),
        subColor: setColor(item.sectorTitle).substring(1),
        width: 590,
      })
    })
    setWinnerList(winnerListTemp)

    resAwardList.forEach((item)=>{
      const colorSet = setColor(item.sectorTitle)
      awardListTemp.push({
        sectorTitle: item.sectorTitle,
        list: item.list.map((listItem)=>{
          return {
            image: listItem.image,
            target: listItem.titlePc,
            maker: listItem.titlePc2,
            type: 'main',
            prize: listItem.awardName,
            color: colorSet,
          }
        })
      })
    })
    setAwardList(awardListTemp)
  }

  const setTableData = async () => {
    if(2022>=Number(activeTab)){
      const historyTable = await import(`../components/winners/tables/${activeTab}`);
      setHistoryTable(historyTable.default)
    }
  }

  useEffect(()=>{
    setEpisode(episodeInfo)
  },[])
  
  useEffect(()=>{
    if(episode){
      let tab = []
      let year = new Date(episode.openDate).getFullYear() - 1
      let lastEpisodeNo = Number(episode.episodeNo) - 1
      setLastYear(year.toString())

      for(let i = lastEpisodeNo; i >= 1; i--){
        tab.push({
          label: `${year}년 ${i}회`,
          value: `${year}`,
        })
        year--;
      }
      
      setTabData(tab)
      setActiveTab(tab[0].value)

      init()
    }
  },[episode])

  const setTitle = () => {
    const activeTabData = tabData.find((item: TabProps) => item.value === activeTab);
    if (activeTabData) {
      setWinnersTitle(activeTabData.label);
    }
  };

  useEffect(()=>{
    setTitle()
    setTableData()
    fadeIn()
  },[tabData,activeTab])

  return (
    <S.Winners>
      <h1>역대수상</h1>
      {tabData.length && <SlideTab tab={tabData} active={activeTab} onChange={setActiveTab} />}
      <hr className='hr'/>
      <p className='winners-title nanum fade-in'>
        {winnersTitle}
      </p>
      {activeTab === lastYear && (
        <>
          <S.WinnersGrand className='fade-in'>
            {
              winnerList && winnerList.map((item:TypeAwardsItem, idx:number)=>{
                return <AwardsItem data={item} key={`${item.prize}_${idx}`}/>
              })
            }
          </S.WinnersGrand>
          {
            awardList && awardList.map((item:TypeAwardList, idx:number)=>{
              return (
                <S.WinnersMain className='fade-in' key={`${item.sectorTitle}_${idx}`}>
                  <p className='main-title'>
                    {item.sectorTitle}
                  </p>
                  <div className='main-content'>
                    {
                      item.list.map((listItem:TypeAwardsItem, idx:number)=>{
                        return <AwardsItem data={listItem} key={`${item.sectorTitle}_${idx}`}/>
                      })
                    }
                  </div>
                </S.WinnersMain>
              )
            })
          }
        </>
      )}
      {activeTab !== '2023' && (
        <S.WinnersTables>
          {historyTable && historyTable.map((item: TypeWinnersTable,idx:number)=>{
            return <WinnersHistoryTable table={item} key={`table_${idx}`}/>
          })}
        </S.WinnersTables>
      )}
    </S.Winners>
  )
}