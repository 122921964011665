import ImageRatio from '../../stories/Images/ImageRatio'
import * as S from './VideoItem.styled'
import VideoModal from './VideoModal'
import {useState} from 'react'
import { createPortal } from 'react-dom';

export type TypeVideo = {
  thumbnail: string,
  title: string,
  vodId: string
}

export default function VideoItem({video}:{video:TypeVideo}) {
	const [popup, setPopup] = useState(false);
  return (
    <>
      <S.VideoItem className='video-item' onClick={()=>{setPopup(true)}}>
        <ImageRatio src={video.thumbnail} width={386} ratio={{x:386, y:218}} hoverEffect={true}/>
        <p className='video-title'>{video.title}</p>
      </S.VideoItem>
      {popup && createPortal(<VideoModal video={video} onClose={()=>{setPopup(false)}}/>,document.body,)}
    </>
  )
}