import { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as S from "./Tab.styled";

export type TabProps = {
  label: string;
  value: string;
  color?: string,
  hash?: string
};

export default function Tab({
  tab,
  active,
  type='button',
  onChange,
  useHash=true,
}: {
  tab: TabProps[];
  active: string;
  type?: 'button'|'text',
  onChange: (value: string) => void,
  useHash?: boolean
}) {
  const [activeTab, setActiveTab] = useState(active);
  const location = useLocation();
  const navigate = useNavigate()

  const buttonClass = (value: string) => `${activeTab === value ? "active":''} ${type}-type`;

  const onActiveTab = (value:string, hash?:string) => {
    setActiveTab(value);
    onChange(value);
  
    if(useHash && hash) navigate(`${location.pathname}#${hash}`, { replace: true });
  };

  useEffect(()=>{
    if(useHash){
      const hash = location.hash.substring(1)
      if(location.hash === ''){
        onActiveTab(tab[0].value)
      }else if(tab.map((item)=>item.hash).includes(hash)){
        const activedTab = tab.filter(item=>{ return item.hash === hash})[0]
        onActiveTab(activedTab.value)
        navigate(`${location.pathname}#${hash}`, { replace: true });
      }else{
        navigate(location.pathname, { replace: true })
      }
    }
  },[location.hash])

  useEffect(()=>{
    setActiveTab(active);
  },[active])

  return (
    <S.Tab
      className={`tab-type-${type}`}
    >
      {tab.map((tab) => (
        <S.TabItem
          $color={tab.color?tab.color:'#111'}
          key={tab.value}
          onClick={() => {
            onActiveTab(tab.value, tab.hash);
          }}
          className={buttonClass(tab.value)}
        >
          {tab.label}
        </S.TabItem>
      ))}
    </S.Tab>
  );
}
