import qs from 'qs';
import { $api } from '../utils/api';
import { NoticeDetail, NoticeList } from './models';

class NoticeService {
  async getNoticeList(query:{page:number, size:number}) {
    let url = '/baeksang/notice/list'
    if (query) {
      url = `${url}?${qs.stringify({query})}`
    }
    return $api.get(url, NoticeList)
  }
  
  async getNoticeDetail(id: number) {
    return $api.get(`/baeksang/notice/${id.toString()}`, NoticeDetail)
  }
}

export const noticeService = new NoticeService();

