export abstract class BaseDto<T> {
	constructor(partial?: Partial<T>) {
		if (partial) {
			this.setAll(partial);
		}
	}

	setAll(partial?: Partial<T>): this {
		Object.assign(this, partial);
		// Object.keys((key: string)=>{ this[key] = partial[key]})
		return this;
	}

	set<K extends keyof this>(field: K, value: any): this {
		Object.assign(this, {
			[field]: value,
		});
		return this;
	}
}
