import styled, { css } from "styled-components";

export const Tab = styled.div`
  display: flex;
  max-width: 1200px;

  ${({ theme }) => theme.media.mobile`
    width: calc(100% + 20px);
  `}

  .slide-wrapper {
    display: flex;
    gap: 30px;
    overflow-x: auto;
    flex-wrap: nowrap;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  ${({ theme }) => theme.media.mobile`
    .slide-wrapper {
      display: flex;
      overflow-x: auto;
      gap: 16px;
      flex-wrap: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
      max-width: calc(100% + 20px);
    }
  `}

  .slide-button{
    flex: 0 0 36px;
    height: 36px;
    border: 1px solid ${({theme}) => theme.colorGray_e1e1e1};
    border-radius: 50%;
    position: relative;

    svg{
      fill: ${({theme}) => theme.colorBlack};
    }
    &.disabled svg {
      fill: ${({theme}) => theme.colorGray_e1e1e1};
    }

    &.next{
      &:not(.disabled)::before {
        content: '';
        position: absolute;
        background: linear-gradient(270deg, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
        top: 0;
        bottom: 0;
        width: 36px;
        height: 36px;
        left: -37px;
      }
    }
    &.prev{
      transform: rotate(180deg);
      &:not(.disabled)::before {
        content: '';
        position: absolute;
        background: linear-gradient(270deg, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
        top: 0;
        bottom: 0;
        width: 36px;
        height: 36px;
        left: -37px;
      }
    }
    ${({ theme }) => theme.media.mobile`
      display:none;
    `}
  }
`

export const TabItem = styled.button.attrs<{$color:string}>(props=>({$color:props.$color}))`
  font-family: Pretendard;
  &:first-child{
    margin-left: 20px;
    ${({ theme }) => theme.media.mobile`
      margin-left: 0px;
    `}
  }
  &:last-child{
    margin-right: 20px
  }
  height: 36px;
  line-height: 36px;
  white-space: nowrap;
  color: ${({theme}) => theme.colorGray_8a8a8a};
  font-size: 22px;
  font-weight: 600;

  &.active{
    color: ${({theme}) => theme.colorGray_111};
  }

  ${({ theme }) => theme.media.mobile`
    font-size: 15px;
    height: 18px;
    line-height: 18px;
  `}
`