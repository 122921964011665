import styled from "styled-components";

export const VideoModal = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 900;

  .contents{
    width: 1008px;
    margin: 0 auto;
    margin-top: calc((100vh - 637px)/2);

    .thumbnail {
      height: 567px;
      
      ${({ theme }) => theme.media.mobile`
        height: calc((100vw - 32px)*.565);
      `}
    }
    
    .close-button{
      margin-bottom: 16px;
      float: right;
    }

    img{
      width: 100%;
    }
    ${({ theme }) => theme.media.mobile`
      width: calc(100% - 32px);
      .close-button{
        margin-bottom: 12px;
      }
      margin-top: 0;
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%)
    `}
  }
`