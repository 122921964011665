import { SectorInfo } from "@/services/models"
import { useContext, useEffect, useState } from "react"
import SplitView from "../components/SplitView"
import { ColorContext } from "../components/layout/layoutSub"
import NomineeItem, { TypeNomineeItem } from "../components/nominees/NomineeItem"
import useColor from "../components/useColor"
import useMobile from '../hooks/useMobile'
import { candidateService } from "../services"
import { fadeIn } from "../stories/Header/Header"
import Tab, { TabProps } from '../stories/Tab/Tab'
import * as S from './Nominee.styled'

export default function Nominee() {
  let { isMobile } = useMobile()
  const getColor = useColor;

  const [sectorInfo, setSectorInfo] = useState<SectorInfo[]>()
  const [activeTab, setActiveTab] = useState<string>('')
  const [activeSubTab, setActiveSubTab] = useState<string>()
  const [subTabData, setSubTabData] = useState<TabProps[]>()
  const [title, setTitle] = useState<string>('')
  const [nomineeList, setNomineeList] = useState<TypeNomineeItem[]>([])
  const [tabList , setTabList] = useState<TabProps[]>()
  const { setColor } = useContext(ColorContext) || {};

  const init = async () => {
    const resSectorList = await candidateService.getSectors()
    setSectorInfo(resSectorList)
    setTabList(resSectorList.map((item, idx)=>{
      return {
        label: item.sectorTitle,
        value: item.sectorId,
        color: getColor(item.sectorTitle),
        hash: `${idx+1}`
      }
    }))

    if(tabList) {
      setActiveTab(tabList[0].value)
    }
  }

  const setNomineeData = async (awardId:string) => {
    const resAwardInfo = await candidateService.getAwardInfo(awardId)
    setNomineeList(
      resAwardInfo.map((item)=>{
        return{
          image: item.image,
          title: item.titlePc,
          maker: item.titlePc2
        }
      })
    )
  }

  useEffect(()=> {
    if(tabList && sectorInfo) {
      const activeTabData = tabList.filter((item)=>item.value === activeTab)[0]
      const activeSubTabData = sectorInfo.filter((item)=>item.sectorId === activeTab)[0]
      setTitle(activeTabData.label)
      setSubTabData(
        activeSubTabData.list.map((item,idx)=>{
          if(!idx) {
            setActiveSubTab(item.awardId)
          }
          return{
            label: item.awardName,
            value: item.awardId
          }
        })
      )
    }
    if(activeTab && tabList && setColor){
      const activeData = tabList.filter((tab)=>tab.value === activeTab)[0]
      setColor(activeData.color?.substring(1))
    }
  },[activeTab])

  useEffect(()=> {
    if(activeSubTab)
      setNomineeData(activeSubTab)
  },[activeSubTab])

  useEffect(()=> {
    init()
  },[])

  useEffect(()=>{
    fadeIn()
  },[nomineeList])

  return (
    <S.Nominee>
      <h3>제60회</h3>
      <h1>{title} 후보</h1>
      {tabList && <Tab tab={tabList} active={activeTab} onChange={setActiveTab} type='button' />}
      {!isMobile ? subTabData&& (
        <SplitView tab={subTabData} reverse={true} onChange={setActiveSubTab} active={activeSubTab}>
          <S.NomineeTabSection>
            {nomineeList.map((item:TypeNomineeItem)=>{
              return(
                <NomineeItem data={item} key={item.title}/>
              )
            })}
          </S.NomineeTabSection>
        </SplitView>
      ):subTabData && activeSubTab &&(
        <S.MoSubTab>
          <Tab tab={subTabData} active={activeSubTab} onChange={setActiveSubTab} type='text' useHash={false}/>
            <S.NomineeTabSection>
              {nomineeList.map((item:TypeNomineeItem)=>{
                return(
                  <NomineeItem data={item} key={item.title}/>
                )
              })}
            </S.NomineeTabSection>
        </S.MoSubTab>
      )}
    </S.Nominee>
  )
}