import styled from "styled-components";

export const Intro = styled.div`
  .intro{
    margin-top: 60px;
    .view-title{
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: -0.75px;
    }
    &-title{
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -0.75px;
      text-align: left;
    }

    ${({ theme }) => theme.media.mobile`${`
      margin-top: 20px;
      .intro-title{
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        margin-top: 10px;
      }
      .view-title{
        font-size: 14px;
        line-height: 21px;
        color: ${theme.colorGray_333};
      }
    `}`}
  }
`

export const IntroBanner = styled.div`
  margin-top: 60px;
  img{
    width: 100%;
  }
  ${({ theme }) => theme.media.mobile`
    margin-top: 20px;
  `}
`

export const IntroSection = styled.div`
  .section{
    &-content{
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.75px;      
      color: ${({ theme }) => theme.colorGray_333};
      white-space: pre-line;
    } 
    &-sub{
      margin-top: 30px;
      padding-top: 30px;
      border-top: 1px solid ${({ theme }) => theme.colorGray_e1e1e1};

      &-title{
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.75px;
        color: ${({ theme }) => theme.colorGray_333};
      }
      &-content{
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.75px;
        margin-top: 16px;
        color: ${({ theme }) => theme.colorGray_333};
        white-space: pre-line;
      }
    }
  }

  ${({ theme }) => theme.media.mobile`
    .section{
      &-content{
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;   
        white-space: normal;
      }
      &-sub{
        margin-top: 20px;
        padding-top: 20px;

        &-title{
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;          
        }
        &-content{
          font-size: 15px;
          font-weight: 400;
          line-height: 22px;
          margin-top: 14px;
          white-space: normal;
        }
      }
    }
  `}
`