import styled from "styled-components";

export const Error = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .title{
    font-size: 80px;
    font-weight: 700;
    line-height: 100px;
    letter-spacing: 1px;
    text-align: center;

    ${({ theme }) => theme.media.mobile`
      font-size: 40px;
      line-height: 50px;
    `} 
  }
  .explain{
    margin-top: 14px;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.5px;
    text-align: center;
    
    ${({ theme }) => theme.media.mobile`
      margin-top: 12px;
      font-size: 16px;
      line-height: 24px;
    `}
  }
  .reload{
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: baseline;
    margin-top: 60px;
    
    ${({ theme }) => theme.media.mobile`
      margin-top: 40px;
    `}
    
    span{
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.5px;
      text-align: center;
      
      ${({ theme }) => theme.media.mobile`
        font-size: 18px;
        line-height: 21px;
      `}
    }
  }
`