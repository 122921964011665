import styled from "styled-components";

export const NomineeItem = styled.div`
  flex: 0 1 auto;
  max-width: calc(50% - 10px);
  ${({ theme }) => theme.media.mobile`
    flex: 1 1 100%;
    max-width: 100%;
  `}
  .title{
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.03em;    
    margin-top: 16px;

    ${({ theme }) => theme.media.mobile`
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.75px;
      margin-top: 12px;
    `}
  }
  .maker{
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.03em;
    text-align: left;
    margin-top: 6px;
    color: ${({ theme }) => theme.colorGray_8a8a8a};
    
    ${({ theme }) => theme.media.mobile`
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: -0.75px;
      margin-top: 6px;
    `}
  }
`