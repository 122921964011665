import { TypeVideo } from '@/components/replay/VideoItem';
import { TabProps } from '@/stories/Tab/Tab';
import { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import ARROW from '../assets/images/replay/view-more.svg';
import VideoItem from '../components/replay/VideoItem';
import useMobile from '../hooks/useMobile';
import { EpisodeInfo } from "../services/models";
import { videoService } from "../services/video";
import SlideTab from '../stories/Tab/SlideTab';
import * as S from './Replay.styled';

export default function Replay() {
  const { episodeInfo } = useOutletContext<{
    episodeInfo: EpisodeInfo
  }>()

  let { isMobile } = useMobile()
  const [tabData, setTabData] = useState<TabProps[]>([]);
  const [activeTab, setActiveTab] = useState<string>('');
  const [replayTitle, setReplayTitle] = useState<string>('');
  const [videoList, setVideoList] = useState<TypeVideo[]>([]);
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [episode, setEpisode] = useState<EpisodeInfo>()
  const videoSize = !isMobile?9:8;

  const getThumbnail = (thumbnail: string) => `${process.env.REACT_APP_fileUrl}${thumbnail}`;

  const getVideoList = useCallback(async (year: number, page: number) => {
    try {
      const resData = await videoService.getVideos(year, page, videoSize);

      const resVideoList = resData.contents.map((item) => ({
        thumbnail: getThumbnail(item.thumbnail),
        title: item.vodTitle,
        vodId: item.vodId,
      }));

      setVideoList((prevList) => (page === 1 ? resVideoList : [...prevList, ...resVideoList]));
      setHasNext(resData.currentPage !== resData.totalPages);
    } catch (error) {
    }
  }, []);

  const onViewMore = async () => {
    await getVideoList(Number(activeTab), Math.ceil(videoList.length / videoSize) + 1);
  };

  useEffect(() => {
    setEpisode(episodeInfo)
  }, []);

  useEffect(()=>{
    if(episode){
      let tab = []
      let year = Number(episode.displayVodService)
      let lastEpisodeNo = Number(episode.episodeNo) - (new Date(episode.openDate).getFullYear() - year)

      for(let i = lastEpisodeNo; i >= 48; i--){
        if(![52,53].includes(i)){
          tab.push({
            label: `${year}년 ${i}회`,
            value: `${year}`,
          })
        }
        year--;
      }
      
      setTabData(tab)
      setActiveTab(tab[0].value)
    }
  },[episode])

  useEffect(() => {
    const activeTabData = tabData.find((item: TabProps) => item.value === activeTab);
    if (activeTabData) {
      setReplayTitle(activeTabData.label);
      getVideoList(Number(activeTab), 1);
    }
  }, [tabData, activeTab, getVideoList]);

  const ReplayVideoList = ({ videoList }: { videoList: TypeVideo[] }) => {
    return (
      <S.ReplayList>
        {videoList.map((item: TypeVideo) => (
          <VideoItem video={item} key={item.title} />
        ))}
      </S.ReplayList>
    );
  };

  return (
    <S.Replay>
      <h1>다시보기</h1>
      {tabData.length && <SlideTab tab={tabData} active={activeTab} onChange={setActiveTab} />}
      <hr className='hr' />
      <p className='replay-title nanum'>{replayTitle}</p>
      <ReplayVideoList videoList={videoList} />
      {hasNext && (
        <div className='view-more'>
          <S.ViewMore onClick={onViewMore}>
            <span>더보기</span>
            <img src={ARROW} alt='' />
          </S.ViewMore>
        </div>
      )}
    </S.Replay>
  );
};
