import { createBrowserRouter } from 'react-router-dom';
import Layout from './components/layout/layout';
import LayoutSub from './components/layout/layoutSub';
import Home from './routes/Home';
import History from './routes/History';
import Intro from './routes/Intro';
import Nominee from './routes/Nominee';
import Awards from './routes/Awards';
import Winners from './routes/Winners';
import Replay from './routes/Replay';
import Notice from './routes/Notice';
import Vote from './routes/Vote';
import Error from './routes/Error'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: '/history',
        element: <History />
      },
    ],
    errorElement: <Error />,
  },
  {
    path: '/',
    element: <LayoutSub />,
    children: [
      {
        path: '/intro',
        element: <Intro />
      },
      {
        path: '/nominees',
        element: <Nominee />
      },
      {
        path: '/awards',
        element: <Awards />
      },
      {
        path: '/winners',
        element: <Winners />
      },
      {
        path: '/replay',
        element: <Replay />
      },
      {
        path: '/vote',
        element: <Vote />
      },
      {
        path: '/notice',
        element: <Notice />
      },
    ],
    errorElement: <Error />,
  }
])