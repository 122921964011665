import styled from "styled-components";

export const MainVideo = styled.div`

  position: relative;
  height: 100vh;
  background-color: #E4C6C2;
  
  ${({ theme }) => theme.media.mobile`
    height: 70vh;
  `}

  .content{
    position: absolute;
    z-index: 1;
    color: ${({ theme }) => theme.colorWhite};
    width: calc(100% - 280px);
    bottom: 110px;
    left: 50%;
    transform: translateX(-50%);
    
    ${({ theme }) => theme.media.mobile`
      padding: 0 20px;
      width: 100%;
      bottom: 50px;
    `}
  }
  
  .description{
    font-size: 16px;
    font-weight: 350;
    line-height: 23px;
    letter-spacing: -0.75px;
    
    ${({ theme }) => theme.media.mobile`
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
    `}
  }

  .title{
    margin-top: 33px;
    font-size: ${({ theme: { functions } }) => functions.pxToVw(1920, 80)};
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
    
    ${({ theme }) => theme.media.mobile`${`
      margin-top: 12px;
      font-size: ${theme.functions.pxToVw(360, 30)};
      line-height: ${theme.functions.pxToVw(360, 39)};
    `}`}
  }

  hr.hr{
    margin: 66px 0 20px 0;
    height: 1px;
    background-color: ${({ theme }) => theme.colorWhite};
    border: none;

    ${({ theme }) => theme.media.mobile`
      margin: 26px 0 0 0;
    `}
  }

  .scroll-down{
    display: flex;
    gap: 10px;
    align-items: center;

    button {
      cursor: default;
      transform: rotate(90deg);
    }

    font-size: 16px;
    font-weight: 350;
    line-height: 23px;
    letter-spacing: 0em;

    ${({ theme }) => theme.media.mobile`
      display: none;
    `}
  }

  .video{
    width: 100vw;
    video{
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
    ${({ theme }) => theme.media.mobile`
      height: 100%;
      position: relative;
      
      video{
        height: 100%;
        object-fit: cover;
      }
    `}
  }
`