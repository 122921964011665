import styled from "styled-components";

export const AwardsItem = styled.div.attrs<{$color:string}>((props)=>({
  $color: props.$color
}))`
  flex: 0 1 183px;

  &.type-grand{
    position: relative;
    flex: 1 1 183px;

  }
  &.type-main{
    ${({ theme }) => theme.media.mobile`
      flex: 0 1 calc(50% - 8px);
    `}
  }

  .prize{
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.75px;
    margin-top: 16px;
    color: ${({$color})=>$color};
    
    ${({ theme }) => theme.media.mobile`
      font-size: 13px;
      font-weight: 500;
      margin-top: 12px;
    `}
  }

  .target {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.75px;
    margin-top: 6px;
    white-space: pre-line;

    ${({ theme }) => theme.media.mobile`
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      margin-top: 4px;
    `}
  }

  .maker{
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.75px;
    text-align: left;
    color: ${({ theme }) => theme.colorGray_8a8a8a};
    margin-top: 6px;
    white-space: pre-line;
    
    ${({ theme }) => theme.media.mobile`
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      margin-top: 4px;
    `}
  }
`

export const GrandPrize = styled.div.attrs<{$color:string}>((props)=>({
  $color: props.$color
}))`
  position: absolute;
  width: 100%;
  height: 422px;
  bottom:0;
  background: linear-gradient(0deg, ${({$color})=>$color} 31.74%, rgba(251, 62, 39, 0) 100%);
  padding-top: 209px;
  
  ${({ theme }) => theme.media.mobile`
    height: 184px;
    padding-top: 74px;
  `}

  &::before{
    content: '';
    position: absolute;
    bottom:0;
    width: 100%;
    height: 107px;

    ${({ theme }) => theme.media.mobile`
      height: 54px;
    `}
  }
  &.sub-06AFDB::before{
    background: linear-gradient(0deg, #FF86EC 0%, rgba(255, 134, 236, 0) 100%);
  }
  &.sub-FF4A57::before{
    background: linear-gradient(0deg, #ACEC20 0%, rgba(255, 74, 87, 0) 100%);
  }

  .grand-target{
    text-align: center;
    padding-bottom: 18px;

    ${({ theme }) => theme.media.mobile`
      padding-bottom: 9px;
    `}

    span{
      color: ${({ theme }) => theme.colorWhite};
      font-size: 57px;
      font-weight: 600;
      line-height: 66px;
      letter-spacing: -1px;
      text-align: center;
      border-bottom: 2px solid ${({ theme }) => theme.colorWhite};
      padding-bottom: 18px;
      position: relative;
      z-index: 9;

      ${({ theme }) => theme.media.mobile`${`
        font-size: 28px;
        line-height: 32px;
        padding-bottom: 9px;
        border-bottom: 1px solid ${theme.colorWhite};
      `}`}
    }
  }

  .grand-prize{
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: -1px;
    text-align: center;
    color: ${({ theme }) => theme.colorWhite};
    margin-top: 18px;
    position: relative;
    z-index: 9;

    ${({ theme }) => theme.media.mobile`
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.75px;
      margin-top: 9px;
    `}
  }
`