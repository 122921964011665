import { BaseDto } from './base';

export class LastAwardInfo extends BaseDto<LastAwardInfo> {
  episodeId = '';
  sectorId = '';
  sectorTitle = '';
  isExistWinner = false;
  winner = {
    winnerTitleMo: '',
    winnerTitleMo2: '',
    winnerTitlePc2: '',
    image: '',
    winnerAwardName: '',
    winnerId: '',
    winnerTitlePc: '',
    imageAlt: ''
  };
  list = [
    {
      titleMo2: '',
      image: '',
      awardeeId: '',
      awardName: '',
      imageAlt: '',
      titlePc2: '',
      titleMo: '',
      titlePc: '' 
    }
  ]

	constructor(partial?: Partial<LastAwardInfo>) {
		super();

		Object.assign(this, partial);
	}
}