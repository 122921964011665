import styled, { css } from "styled-components";

export const IconButton = styled.button.attrs<{ $size?: number; $reverse?: boolean; $hover?: boolean; $rounded?: boolean }>(props => ({
  $size: props.$size || 30
}))`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-color: ${({ theme }) => theme.colorGray_e1e1e1};
  background-color: ${({ theme }) => theme.colorWhite};
  ${({ $rounded }) => $rounded && css`
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
  `}
  svg {
    fill: ${({ theme }) => theme.colorGray_111};
  }
  ${({$hover}) => $hover && css`
    border-color: ${({ theme }) => theme.colorGray_333};
    background-color: ${({theme}) => theme.colorGray_111};
    svg {
      fill: ${({theme}) => theme.colorWhite};
    }
  `}

  ${({$reverse, $hover, theme}) => $reverse && css`
    background-color: ${theme.colorGray_111};
    border-color: ${theme.colorGray_333};
    svg {
      fill: ${theme.colorWhite};
    }
    ${$hover && css`
      border-color: ${({ theme }) => theme.colorGray_e1e1e1};
      background-color: ${({ theme }) => theme.colorWhite};
      svg {
        fill: ${({ theme }) => theme.colorGray_111};
      }
    `}
  `}

  .name {
    color: ${({ theme }) => theme.colorGray_111};
    font-weight: 500;
    font-size: ${({ $size }) => Number($size) > 36 ? 14 : 10}px;
    letter-spacing: -0.2px;
    ${({$hover}) => $hover && css`
      color: ${({ theme }) => theme.colorWhite};
    `}
  }
`