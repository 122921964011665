import { ReactComponent as SvgArrowNext } from './icons/IconArrowNext.svg'
import { ReactComponent as SvgPlus } from './icons/IconPlus.svg'
import { ReactComponent as SvgMenu } from './icons/IconMenu.svg'
import { ReactComponent as SvgClose } from './icons/IconClose.svg'
import { ReactComponent as SvgRefresh } from './icons/IconRefresh.svg'
import { ReactComponent as SvgSolidNext } from './icons/IconSolidNext.svg'
import { theme } from '../../styles/theme'
export const icons = ['next', 'plus', 'close', 'menu', 'refresh', 'solidNext'] as const
export type Icons = typeof icons[number]
export type IconProps = {
  name: Icons, width?: number, height?: number, fill?: string
}

export default function SvgIcon({
  name,
  width,
  height,
  fill,
}: IconProps) {
  let icon
  switch (name) {
    case 'next':
      icon = <SvgArrowNext width={width || 16} height={height || 16} fill={fill || theme.colorGray_111} />
      break
    case 'plus':
      icon = <SvgPlus width={width || 18} height={height || 18} fill={fill || theme.colorGray_111} />
      break
    case 'menu':
      icon = <SvgMenu width={width || 24} height={height || 24} fill={fill || theme.colorBlack} />
      break
    case 'close':
      icon = <SvgClose width={width || 18} height={height || 18} fill={fill || theme.colorWhite} />
      break
    case 'refresh':
      icon = <SvgRefresh width={width || 16} height={height || 16} fill={fill || theme.colorWhite} />
      break
    case 'solidNext':
      icon = <SvgSolidNext width={width || 16} height={height || 16} fill={fill || theme.colorGray_111} />
      break
    default:
      icon = <></>
  }
  return icon
}