import styled from "styled-components";

export const History = styled.div`
  background-color: ${({theme}) => theme.colorBlack};
  position: relative;
  padding-bottom: 400px;
  margin-bottom: -200px;

  &::before{
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 483px;
    background: linear-gradient(180deg, #8EE220 0%, rgba(255, 74, 87, 0) 90%);
    left: 0;

    ${({ theme }) => theme.media.mobile`
    background: linear-gradient(180deg, #8EE220 0%, rgba(255, 74, 87, 0) 70%);
      height: 304px;
    `}
  }

  .history-content{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    gap: 190px;

    ${({ theme }) => theme.media.mobile`
      gap: 100px;
    `}
  }
`

export const HistoryHeader = styled.div`
  background: linear-gradient(180deg, #FF4A57 32.28%, rgba(255, 74, 87, 0) 100%);
  padding-top: 260px;
  padding-bottom: 267px;
  text-align: center;

  ${({ theme }) => theme.media.mobile`
    background: linear-gradient(180deg, #FF4A57 45.69%, rgba(255, 74, 87, 0) 100%);
    padding-top: 130px;
    padding-bottom: 100px;
  `}
  
  .history{
    &-since{
      font-size: 30px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: -0.03em;
      color: ${({theme}) => theme.colorWhite};
      position: relative;
      z-index: 3;

      ${({ theme }) => theme.media.mobile`
        font-size: 18px;
        line-height: 23px;
      `}
    }

    &-title{
      margin-top: 40px;
      font-size: 100px;
      font-weight: 800;
      line-height: 125px;
      letter-spacing: 1px;
      color: ${({theme}) => theme.colorWhite};
      position: relative;
      z-index: 3;
      
      ${({ theme }) => theme.media.mobile`
        margin-top: 8px;
        font-size: 36px;
        line-height: 45px;
      `}
    }

    &-desc{
      margin-top: 70px;
      font-size: 20px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.75px;
      color: ${({theme}) => theme.colorGray_e1e1e1};
      position: relative;
      z-index: 3;
      
      ${({ theme }) => theme.media.mobile`
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin-top: 30px;
      `}
    }
  }
`

export const HistoryItem = styled.div`
  width: 1200px;
  flex: 0 0 100%;
  padding: 0 calc(50% - 600px);
  display: flex;
  gap: 80px;
  justify-content: space-between;
  position: relative;
  z-index: 10;

  .image-wrap, .history-data {
    opacity: 0.1;
    transition: 0.2s;

    &.opacity-active {
      opacity: 1;
      transition: 0.2s;
    }
  }
  ${({ theme }) => theme.media.mobile`
    .image-wrap {
      opacity: 1;
    }
  `}

  ${({ theme }) => theme.media.mobile`
    width: auto;
    flex: 1 0 100%;
    justify-content: flex-start;
    gap: 46px;
  `}

  &:nth-child(even){
    flex-direction: row-reverse;
    text-align: right;
    .history-link{
      right:0;
    }
    ${({ theme }) => theme.media.mobile`
      flex-direction: row;
      text-align: left;
      .history-link{
        position: static;
        margin-top: 30px;
      }
    `}
  }

  img{
    width: 518px;
  }
  .history {
    &-data{
      position: relative;
      
      ${({ theme }) => theme.media.mobile`
        width: calc(100% - 66px);
        .image-ratio img{
          width: 100%;
        }
      `}
    }
    &-year{
      color: ${({theme}) => theme.colorWhite};
      font-size: 60px;
      font-weight: 700;
      line-height: 75px;
      letter-spacing: -0.75px;
      ${({ theme }) => theme.media.mobile`
        font-size: 32px;
        line-height: 40px;
        margin-top: 24px;
      `}
    }
    &-desc{
      width: 518px;
      color: ${({theme}) => theme.colorGray_e1e1e1};
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.75px;
      margin-top: 20px;
      white-space: pre-line;

      ${({ theme }) => theme.media.mobile`
        width: auto;
        font-size: 13px;
        line-height: 21px;
        margin-top: 8px;
      `}
    }
    &-link{
      position: absolute;
      bottom:0;
      display: flex;
      gap: 6px;
      align-items: center;

      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: -0.75px;
      color: ${({theme}) => theme.colorGray_8a8a8a};

      button{
        background-color: ${({theme}) => theme.colorBlack};
        svg{
          fill: #8a8a8a;
        }
      }
      ${({ theme }) => theme.media.mobile`
        position: static;
        margin-top: 30px;
        gap: 4px;
        font-size: 12px;
        line-height: 14px;
      `}
    }
  }

`
// background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #ACEC20 64.06%, #FF4A57 100%);

export const HistoryTimeLine = styled.div`
  position: absolute;
  width: 3px;
  content: '';
  top: 0;
  bottom: -50vh;
  left: calc(50% - 1.5px);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0.5%, #ACEC20 5%, #FF4A57 10%, #FF4A57 100%);
  z-index: 0;
  
  ${({ theme }) => theme.media.mobile`
    widht: 2px;
    left: 24px;
  `}

  span{
    content: '';
    display: block;
    position: sticky;
    top: 50%;
    height: 50vh;
    width: 3px;
    background: linear-gradient(180deg, rgba(51, 51, 51, 1) 0%, rgba(51, 51, 51, 1) 1.72%, rgba(51, 51, 51, 1) 88.08%, rgba(0, 0, 0, 1) 100%);
    z-index: 9;
  }

  &::after{
    content: '';
    display: block;
    position: absolute;
    width: 3px;
    background: linear-gradient(rgba(51, 51, 51, 1) 0%, rgba(0, 0, 0, 1) 3%, rgba(0, 0, 0, 1) 100%);
    height: 50vh;
    transform: translateY(-17px);
    bottom:0;
    z-index: 99;
  }
`

export const HistoryTimePoint = styled.div`
  width: 3px;
  position: relative;

  ${({ theme }) => theme.media.mobile`${`
    width: 2px;
    left: 24px;
  `}`}

  span {
    display: block;
    content: '';
    position: sticky;
    top: 50%;
    transform: translateX(-7px);
    width: 17px;
    height: 17px;
    border: 3px solid rgba(14, 14, 14, 1);
    background: ${({theme}) => theme.colorGray_333};
    border-radius: 50%;
    z-index: 10;

    &.point-active{
      background: rgba(255, 74, 87, 1);
    }

    ${({ theme }) => theme.media.mobile`${`
      width: 14px;
      height: 14px;
      border: 2px solid ${theme.colorBlack};
      transform: translateX(-5.5px);
    `}`}
  }
`