import { DefaultTheme, css } from 'styled-components'
type BackQuoteArgs = string[];
export const theme: DefaultTheme = {
  media: {
    mobile: (...args: BackQuoteArgs) => css`@media only screen and (max-width: 1199px) {
      ${args}
    }`,
  },
  colorBlack: '#000',
  colorWhite: '#fff',
  colorGray_111: '#111',
  colorGray_333: '#333',
  colorGray_8a8a8a: '#8a8a8a',
  colorGray_e1e1e1: '#e1e1e1',
  colorDivisionTv: '#FB3E27',
  colorDivisionMovie: '#6700D7',
  colorDivisionTheater: '#04A248',
  gradientMain: 'linear-gradient(90deg, #6701D7 0%, #04A248 100%)',
  gradientTv: 'linear-gradient(90deg, #FB3E27 0%, rgba(251, 62, 39, 0.00) 100%)',
  gradientMovie: 'linear-gradient(90deg, #6700D7 0%, rgba(103, 0, 215, 0.00) 100%)',

  functions: {
    pxToVw: (browserWidth: number, pxSize: number) =>
      ((pxSize / browserWidth) * 100).toFixed(1) + 'vw',
  },
}
