import * as S from './Accordion.styled'
import IconButtons from "../Buttons/IconButtons"
import useMobile from '../../hooks/useMobile'
import {ReactNode, useState} from 'react'

export default function Accordion ({
  title,
  add,
  opened=false,
  border=false,
  children,
  hoverEffect=true
}:{
  title: string,
  add?: string,
  opened?: boolean,
  border?: boolean
  children: ReactNode,
  hoverEffect?: boolean
}) {
  let { isMobile } = useMobile()
  const [active, setActive] = useState(opened)

  const onActive = () => {
    setActive(!active)
  }

  return (
    <S.Accordion className={`accordion ${border ? 'border':''} ${add ? 'add':''}`}>
      <div className="header">
        <div className={`accordion-title ${add ?'has-add':''}`}>
          <span className={`accordion-title-text ${add ? '':'nanum'}`}>
            {title}
          </span>
          {add && (
            <span className='add-text'>
              {add}
            </span>
          )}
        </div>
        <div className='button'>
          <IconButtons
            size={!isMobile?30:26}
            icon={{
              name: !active?'plus':'close',
              width: !isMobile?18:14,
              height: !isMobile?18:14
            }}
            onClick={()=>{onActive()}}
            rounded={true}
            hoverEffect={hoverEffect && !isMobile}
            reverse={active}
          />
        </div>
      </div>
      <div className={`body ${active?'active':''}`}>
        {children}
      </div>
    </S.Accordion>
  )
}