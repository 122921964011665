import styled from "styled-components";

export const Accordion = styled.div`
  &.border{
    padding-top: 20px;
    border-top: 1px solid ${({ theme }) => theme.colorGray_111};
    margin-top: 66px;
    ${({ theme }) => theme.media.mobile`${`
      padding-top: 16px;
      border-top: 1px solid ${theme.colorGray_e1e1e1};
      margin-top: 30px;
    `}`}
  }

  

  &.add{
    .header{
      align-items: center;
      height: 90px;
      gap: 30px;
    }
    .body{
      margin-top: 10px;
      ${({ theme }) => theme.media.mobile`
        margin-top: 0px;
     `}
      &.active {
        ${({ theme }) => theme.media.mobile`
          margin-top: 0px;
       `}
        padding-bottom: 30px;
      }
    }
  }

  .header{
    display: flex;
    justify-content: space-between;

    ${({ theme }) => theme.media.mobile`${`
      align-items: center;
    `}`}

    .accordion-title{
      &-text{
        font-size: 52px;
        font-weight: 700;
        line-height: 65px;
        letter-spacing: -0.75px;
        
        ${({ theme }) => theme.media.mobile`
          font-size: 30px;
          font-weight: 700;
          line-height: 38px;
          letter-spacing: -0.75px;
        `}
      }
      &.has-add {
        flex: 1 0 auto;
        justify-content: space-between;
        display: flex;
        align-items: center;

        ${({ theme }) => theme.media.mobile`
          flex-direction: column;
          align-items: flex-start;
          gap: 6px;
        `}

        .accordion-title-text{
          font-family: Pretendard;
          font-size: 22px;
          font-weight: 600;
          line-height: 26px;
          letter-spacing: -0.75px;

          ${({ theme }) => theme.media.mobile`
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: -0.75px;
         `}
        }
      }
      .add-text{
        font-family: Pretendard;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        color: ${({ theme }) => theme.colorGray_8a8a8a};

        ${({ theme }) => theme.media.mobile`
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
       `}
      }
    }
  }
  .body{
    height: auto;
    max-height: 0;
    transition: 0.3s;
    overflow: hidden;

    &.active{
      margin-top: 30px;
      max-height: 1000px;
      transition: 0.3s;
      ${({ theme }) => theme.media.mobile`
        margin-top: 20px;
     `}
    }
  }
`