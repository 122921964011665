import styled from "styled-components";

export const Winners = styled.div`
  .left{
    flex: 0 1 285px;
  }

  .tab-slide{
    margin-top: 60px;
    ${({ theme }) => theme.media.mobile`
      margin-top: 40px;
    `}
  }

  hr.hr{
    margin-top: 100px;
    background-color: ${({ theme }) => theme.colorGray_111};
    ${({ theme }) => theme.media.mobile`
      margin-top: 16px;
      border: 0;
      height: 1px;
    `}
  }

  .winners-title{
    margin-top: 13px;
    font-size: 36px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: -0.75px;

    ${({ theme }) => theme.media.mobile`
      margin-top: 16px;
      font-size: 22px;
      font-weight: 700;
      line-height: 28px;
    `}
  }
`

export const WinnersGrand = styled.div`
  display: flex;
  gap: 20px;

  margin-top: 34px;
  position: relative;
  width: 590px;

  ${({ theme }) => theme.media.mobile`
    margin-top: 34px;
    flex-wrap: wrap;
    max-width: 100%;
  `}

  .type-grand {
    flex: 0 1 590px;
    ${({ theme }) => theme.media.mobile`
      position: relative;
    `}
    .image-ratio {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      }
    }
  }

  .grand{
    height: 335px;
    padding-top: 160px;

    ${({ theme }) => theme.media.mobile`
      height: 205px;
      padding-top: 96px
    `}

    &::before{
      height: 125px;
      
      ${({ theme }) => theme.media.mobile`
        height: 64px;
      `}
    }

    &-target{
      span{
        font-size: 43px;
        font-weight: 600;
        line-height: 49px;
        letter-spacing: -1px;
        padding-bottom: 15px;

        ${({ theme }) => theme.media.mobile`
          font-size: 28px;
          line-height: 32px;
          letter-spacing: -0.75px;
          padding-bottom: 8px
        `}
      }
    }

    &-prize{
      margin-top: 15px;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -1px;

      ${({ theme }) => theme.media.mobile`
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.75px;
        margin-top: 8px
      `}
    }
  }
`

export const WinnersMain = styled.div`
  margin-top: 80px;
  &:first-child{
    margin-top: 100px;
  }

  ${({ theme }) => theme.media.mobile`
    margin-top: 40px;
  `}
  
  .main {
    &-title {
      margin-bottom: 24px;
      font-size: 26px;
      font-weight: 600;
      line-height: 31px;
      letter-spacing: -0.75px;

      ${({ theme }) => theme.media.mobile`
        margin-bottom: 18px;
        font-size: 18px;
        line-height: 21px;
      `}

    }
    &-content{
      display: flex;
      gap: 40px 20px;
      flex-wrap: wrap;
      
      ${({ theme }) => theme.media.mobile`
        flex-wrap: wrap;
        max-width: 100%;
        gap: 24px 16px;
      `}
    }
  }
`

export const WinnersTables = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 36px;

  border: 1px solid ${({theme}) => theme.colorGray_e1e1e1};

  table:nth-child(odd){
    border-right: 1px solid ${({theme}) => theme.colorGray_e1e1e1};
    margin-bottom: -1px;
    
    ${({ theme }) => theme.media.mobile`
      border: 0;
    `}
  }
  table:nth-child(even){
    border-bottom: 1px solid ${({theme}) => theme.colorGray_e1e1e1};
    border-left: 1px solid ${({theme}) => theme.colorGray_e1e1e1};
    margin-left: -1px;
    margin-bottom: -1px;
  }
`