export default function useColor(sectorId: string): string {
  const sectorList = [
    {
      id: 'BST896583NygNpj',
      title: 'TV',
      color: '#06AFDB',
    },
    {
      id: 'BST851163dxvFEB',
      title: '영화',
      color: '#FF4A57',
    },
    {
      id: 'BST215363ogiEit',
      title: '연극',
      color: '#00815D'
    },
    {
      id: 'BST215363ogiEit',
      title: '특별',
      color: '#00815D'
    }
  ];

  const getColorById = sectorList.find(item => item.id === sectorId);
  const getColorByTitle = sectorList.find(item => sectorId.includes(item.title));

  return getColorById?.color ?? getColorByTitle?.color ?? '#00815D';
}