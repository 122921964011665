import styled from "styled-components";

export const Nominee = styled.div`
  .left{
    flex: 0 1 285px;
  }
  .tab-type-button{
    margin-top: 60px;
    ${({ theme }) => theme.media.mobile`
      margin-top: 40px;
    `}
  }
`

export const MoSubTab = styled.div`
  margin-top: 14px;
  padding-top: 14px;
  border-top: 1px solid ${({ theme }) => theme.colorGray_111};
`


export const NomineeTabSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  row-gap: 40px;
  ${({ theme }) => theme.media.mobile`
    row-gap: 24px;
  `}
`