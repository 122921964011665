import { EpisodeInfo } from "@/services/models";
import { createContext, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { Outlet, useLocation } from "react-router-dom";
import { episodeService } from "../../services";
import Footer from "../../stories/Footer/Footer";
import Header, { fadeIn } from "../../stories/Header/Header";
import * as S from './layoutSub.styled';

export const ColorContext = createContext<{color: string, setColor: Function}|null>(null);

export default function Layout() {
  const location = useLocation();
  const [hash, setHash] = useState('')
  const [episodeInfo, setEpisodeInfo] = useState<EpisodeInfo>()
  const [color, setColor] = useState<string>('');
  const [windowSize, setWindowSize] = useState<number>()
  const breakPoint = 1200;
  const tolerance = 10;

  const init = async () => {
    const resEpisode = await episodeService.getEpisode()
    setEpisodeInfo(resEpisode)
  }

  useEffect(()=>{
    if(location.hash !== ''){
      const getHash = location.hash.substring(1)
      setHash(getHash)
    }else{
      setHash('')
    }
  },[location.hash])

  useEffect(()=>{
    if(!['/nominees', '/awards'].includes(location.pathname)){
      setColor('')
    }
    window.scrollTo({ top: 0 });
  },[location])

  useEffect(() => {
    init()
    setWindowSize(window.innerWidth)
    window.scrollTo({ top: 0 });

    const handleResize = (e:Event) => {
      setWindowSize(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(()=>{
    if (windowSize) fadeIn();
  },[windowSize])
  return <>
    {
      episodeInfo && (
        <ColorContext.Provider value={{ color, setColor }}>
            <S.Layout>
              <Helmet>
                <meta property="og:title" content={episodeInfo.ogTitle} />
                <meta property="og:image" content={episodeInfo.ogImage} />
                <meta property="og:description" content={episodeInfo.ogDisc} />
              </Helmet>
              <Header episodeInfo={episodeInfo} />
                <S.Main className={`layout-main ${hash} B_${color}`}>
                  <div className="main">
                    <Outlet context={{ episodeInfo }} />
                  </div>
                </S.Main>
              <Footer episodeInfo={episodeInfo} />
            </S.Layout>
        </ColorContext.Provider>
      )
    }
  </>
}