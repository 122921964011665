import styled, { css } from "styled-components";

export const Tab = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: auto;

  ${({ theme }) => theme.media.mobile`
    width: calc(100% + 20px);
    padding-right: 20px;
    gap: 6px;

    &.tab-type-text{
      gap: 16px;
      padding-bottom: 30px
    }
  `}
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const TabItem = styled.button.attrs<{$color:string}>(props=>({$color:props.$color}))`
  white-space: nowrap;
  font-family: Pretendard;

  &.button-type{
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    color: ${({theme}) => theme.colorGray_8a8a8a};
    padding: 12px 24px;
    border: 1px solid ${({theme}) => theme.colorGray_e1e1e1};
    border-radius: 100px;
  
    ${({ theme }) => theme.media.mobile`
      padding: 10px 14px;
      font-size: 15px;
    `}
  
    &.active {
      font-family: Pretendard;
      font-weight: 600;
      background-color: ${({$color}) => $color};
      color: ${({theme}) => theme.colorWhite};
      border: none;
    }
  }

  &.text-type{
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${({theme}) => theme.colorGray_8a8a8a};
    z-index: 8;

    &.active{
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      color: ${({theme}) => theme.colorGray_111};
    }
  }
`