import * as S from './IconButtons.styled'
import SvgIcon, { type IconProps } from '../Icons/Icon'
import useHover from '../../hooks/useHover'
import { ReactNode } from 'react'
export default function IconButtons({
  children,
  size,
  onClick,
  icon,
  rounded = true,
  isHover = false,
  reverse = false,
  hoverEffect = false,
  className,
}: {
  children?: ReactNode,
  size?: number,
  onClick: Function,
  icon?: IconProps,
  rounded?: boolean
  isHover?: boolean
  reverse?: boolean
  hoverEffect?: boolean
  className?:string
  }) {
  const {
    hover,
    setHover
  } = useHover()

  const onMouseEnter = () => {
    if (!hoverEffect) return
    setHover(true)
  }

  const onMouseLeave = () => {
    if (!hoverEffect) return
    setHover(false)
  }
  return (
    <S.IconButton
      onClick={() => onClick()}
      $size={size}
      $reverse={reverse}
      $rounded={rounded}
      $hover={isHover || hover}
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
      className={className}
    >
      {
        children && <span className="name">{children}</span>
      }
      {
        icon && <SvgIcon {...icon} />
      }
      
    </S.IconButton>
  )
}