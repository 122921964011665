import { BaseDto } from './base';

export class Episode extends BaseDto<Episode> {
  programId = '';
	episodeId = '';
	episodeNo = 0;
	openDate = '';
	replayDate = '';

	constructor(partial?: Partial<Episode>) {
		super();

		Object.assign(this, partial);
	}
}

export class EpisodeInfo extends BaseDto<EpisodeInfo> {
  displayAwardee = false
  displayCandidate = false
  displayPoll = false
  displayVodService = ''
  episodeId = ''
  episodeNo = 0
  ogDisc = ''
  ogImage = ''
  ogTitle = ''
  openDate = ''
  replayDate = ''

	constructor(partial?: Partial<EpisodeInfo>) {
		super();

		Object.assign(this, partial);
	}
}