import { TypeVideo } from "@/components/replay/VideoItem"
import { useEffect, useState } from "react"
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import 'swiper/css'
import 'swiper/css/scrollbar'
import { FreeMode, Mousewheel, Scrollbar } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import INTERVIEW_1 from '../assets/images/home/INTERVIEW1.jpg'
import INTERVIEW_10 from '../assets/images/home/INTERVIEW10.jpg'
import INTERVIEW_2 from '../assets/images/home/INTERVIEW2.jpg'
import INTERVIEW_3 from '../assets/images/home/INTERVIEW3.jpg'
import INTERVIEW_4 from '../assets/images/home/INTERVIEW4.jpg'
import INTERVIEW_5 from '../assets/images/home/INTERVIEW5.jpg'
import INTERVIEW_6 from '../assets/images/home/INTERVIEW6.jpg'
import INTERVIEW_7 from '../assets/images/home/INTERVIEW7.jpg'
import INTERVIEW_8 from '../assets/images/home/INTERVIEW8.jpg'
import INTERVIEW_9 from '../assets/images/home/INTERVIEW9.jpg'
import HistorBannerTxt from '../assets/images/home/banner_mo_txt.svg'
import HistorBanner from '../assets/images/home/banner_pc.png'
import VOTE_TEXT from '../assets/images/home/banner_vote_txt.svg'
import VOTE_TEXT_MO1 from '../assets/images/home/banner_vote_txt_mo1.svg'
import VOTE_TEXT_MO2 from '../assets/images/home/banner_vote_txt_mo2.svg'
import SplitView from "../components/SplitView"
import MainVideo from "../components/home/MainVideo"
import VideoSlideItem from "../components/home/VideoSlideItem"
import useMobile from '../hooks/useMobile'
import { awardService, candidateService } from "../services"
import { historyService } from "../services/history"
import { EpisodeInfo, LastAwardInfo, SectorInfo } from "../services/models"
import { videoService } from "../services/video"
import IconButtons from "../stories/Buttons/IconButtons"
import ImageRatio from "../stories/Images/ImageRatio"
import * as S from './Home.styled'


type TypeNomineeList = {
  title: string,
  count: number,
  to: string
}
type TypeInterview = {
  img: string,
  text: string,
  titleInfo: string
  link: string
}

type TypeAwardHistory = {
  year: number,
  link: string,
  list:{
    sector: string,
    winner: string,
    img: string,
    info: string
  }[]
}

type TypeAwardItem = {
  title: string,
  list: string[]
}

export default function Home() {
  const { episodeInfo } = useOutletContext<{
    episodeInfo: EpisodeInfo
  }>()
  const navigate = useNavigate()
  let { isMobile } = useMobile()
  const [activeSum, setActiveSum] = useState<string>('date')
  const [hoverAward, setHoverAward] = useState<number>(0)
  const [randomInterview, setRandomInterview] = useState<TypeInterview[]|[]>([])
  const [randomHistory, setRandomHistory] = useState<TypeAwardHistory[]|[]>([])
  const [sectorInfo, setSectorInfo] = useState<SectorInfo[]>()
  const [mainVideo, setMainVideo] = useState<TypeVideo[]>()
  const [nomineeList, setNomineeList] = useState<TypeNomineeList[]>()
  const [lastAwardInfo, setLastAwardInfo] = useState<LastAwardInfo[]>()
  const [awardList, setAwardList] = useState<TypeAwardItem[]>()
  const [onInit, setOnInit] = useState<boolean>(false)

  const getThumbnail = (thumbnail: string) => `${process.env.REACT_APP_fileUrl}${thumbnail}`;

  const init = async () => {
    const resSectors = await candidateService.getSectors()
    const resVideoList = await videoService.getLatestVideos()
    const resHistory = await historyService.getHistoryList()
    
    if (episodeInfo.displayAwardee){
      const resAwardList = await awardService.getlastAwardInfo()
      setLastAwardInfo(resAwardList)
    }

    setRandomHistory(
      resHistory.map((item)=>({
        year: new Date(item.broadDate).getFullYear(),
        link: `winners#${new Date(item.broadDate).getFullYear()}`,
        list: [
          {
            sector: item.sectorTitle,
            winner: item.titlePc,
            img: item.image,
            info: item.titlePc2
          }
        ]
      }))
    )

    setMainVideo(
      resVideoList.map((item) => ({
        thumbnail: getThumbnail(item.thumbnail),
        title: item.vodTitle,
        vodId: item.vodId,
      }))
    )
    setSectorInfo(resSectors)
    setOnInit(isMobile)
  }

  useEffect(()=>{
    setOnInit(isMobile)
  }, [isMobile])

  const summaryTab = [
    {
      label: '일시',
      value: 'date'
    },
    {
      label: '심사기준',
      value: 'guideline'
    },
    {
      label: '시상부문',
      value: 'category'
    }
  ]
  
  // 백상예술대상 about
  const renderSummaryDate = () => (
    <S.SummaryDate key={'summary_date'}>
      <p className="date nanum">
        2024. 5. 7.<br/>
        화요일 <br/>
        오후 17:00<br/>
      </p>
      <S.SummaryDesc>
        백상예술대상이 올해로 60회째를 맞이합니다. 본 상은 지난 1년간 방영, 상영 또는 <br/>
        공연된 TV/영화/연극부문의 제작진과 출연자에게 시상하는 국내 유일의 종합예술상입니다.
      </S.SummaryDesc>
    </S.SummaryDate>
  );
  
  const renderSummaryGuideline = () => (
    <S.SummaryGuideline key={'summary_guideline'}>
      <p className="guideline nanum">
        백상예술대상은 전문성과 <br/>
        공정성을 최우선 가치에 두고 <br/>
        엄격한 심사를 진행, 후보를 <br/>
        선정한다.
      </p>
      <S.SummaryDesc>
        TV부문 심사 대상은 지상파 종편 케이블 OTT 웹에 제공된 콘텐트로서 최소 4부작 이상,<br/>
        연작의 경우 심사일 기준 1/3 이상 방송된 작품입니다. 영화 부문은 국내에서 개봉 또는 공개된 <br/>
        한국 장편영화가 심사 대상입니다. 연극 부문은 국내에서 공연된 한국 연극이 심사 대상입니다.
      </S.SummaryDesc>
    </S.SummaryGuideline>
  );
  
  const renderSummaryAward = () => (
    <S.SummaryAward key={'summary_award'}>
      <div className="award">
        {
          awardList && awardList.map((item, idx) => (
            <div className="award-list" key={`award_${idx}`}>
              <p className="award-list-title nanum">
                {item.title}
              </p>
              <ul>
                {(idx === 0 || idx === 1) && <li> 대상 </li>}
                {item.list.map((listItem, listIdx) => (
                  <li key={`award_list_${listIdx}`}>
                    {listItem}
                  </li>
                ))}
              </ul>
            </div>
          ))
        }
      </div>
    </S.SummaryAward>
  );
  
  const tabSumContent = () => {
    if (isMobile) {
      return [
        renderSummaryDate(),
        renderSummaryGuideline(),
        renderSummaryAward()
      ];
    } else {
      switch (activeSum) {
        case 'date':
          return renderSummaryDate();
        case 'guideline':
          return renderSummaryGuideline();
        case 'category':
          return renderSummaryAward();
        default:
          return null;
      }
    }
  };

  // 후보
  const nomineeContent = () => {
    return (
      <div className="nominee">
        {
          nomineeList && nomineeList.map((item:TypeNomineeList)=> {
            return (
              <div className="nominee-list" key={item.title} onClick={()=>{onNominees(item.to)}}>
                <span className="nominee-list-title nanum">
                  {item.title.split(' ')[0]}
                </span>
                <span className="nominee-list-count">
                  {item.count} awards
                </span>
                <IconButtons
                  className="nominee-list-link"
                  key={item.title}
                  size={!isMobile?54:34}
                  icon={{
                    name: 'next',
                    width: !isMobile?18:16,
                    height: !isMobile?18:16,
                  }}
                  onClick={()=>{}}
                  rounded={true}
                  hoverEffect={true}
                  reverse={true}
                />
              </div>
            )
          })
        }
      </div>
    )
  }

  // 인터뷰 리스트
  const interviewList: TypeInterview[] = [
    {
      img: INTERVIEW_1,
      text: `"앞으로도 '믿보배'가 될 수 있도록 할게요"`,
      titleInfo: '59회 · TV부문 대상 · 박은빈',
      link: 'https://www.youtube.com/watch?v=3oob_1cAvRI'
    },
    {
      img: INTERVIEW_2,
      text: `"지금보다 더 낮아지기 위해 애쓰는 배우가 돼야죠"`,
      titleInfo: '59회 · 영화부문 남자 최우수 연기상 · 류준열',
      link: 'https://www.youtube.com/watch?v=yqHoR-0NsGk'
    },
    {
      img: INTERVIEW_3,
      text: `"나를 알아가는 배우가 되고 싶어요"`,
      titleInfo: '59회 · 연극부문 연기상 · 하지성',
      link: 'https://www.youtube.com/watch?v=3Mq6lud6B8k'
    },
    {
      img: INTERVIEW_4,
      text: `"얼굴이 다양한 배우가 되고 싶어요"`,
      titleInfo: '59회 · TV부문 여자 조연상 · 임지연',
      link: 'https://www.youtube.com/watch?v=OuoerTVyd9w'
    },
    {
      img: INTERVIEW_5,
      text: `"오롯이 제 자신으로서 당당하고파"`,
      titleInfo: '58회 · TV부문 남자 최우수 연기상 · 이준호',
      link: 'https://www.youtube.com/watch?v=Orcza0jF6hM'
    },
    {
      img: INTERVIEW_6,
      text: `"배우로서 여전히 꿈꾸고 있어요"`,
      titleInfo: '58회 · 영화부문 남자 조연상 · 조우진',
      link: 'https://www.youtube.com/watch?v=Y5jEt3EIxpE'
    },
    {
      img: INTERVIEW_7,
      text: `"남은 30대는 여전히 빛날 거예요"`,
      titleInfo: '58회 · TV부문 여자 최우수 연기상 · 김태리',
      link: 'https://www.youtube.com/watch?v=3d1CNVD0LTI'
    },
    {
      img: INTERVIEW_8,
      text: `"이 순간에 취해있지 않을 거예요"`,
      titleInfo: '57회 · TV부문 남자 신인 연기상 · 이도현',
      link: 'https://www.youtube.com/watch?v=C65Ihs-9yxY'
    },
    {
      img: INTERVIEW_9,
      text: `"진짜 기억이 안났어요"`,
      titleInfo: '57회 · 영화부문 남자 신인 연기상 · 홍경',
      link: 'https://www.youtube.com/watch?v=eFhFUuFttnQ'
    },
    {
      img: INTERVIEW_10,
      text: `"사람들이 이렇게 기억해주면 좋겠어요"`,
      titleInfo: '57회 · TV부문 여자 예능상 · 장도연',
      link: 'https://www.youtube.com/watch?v=CuBLmgWIOvc'
    }
  ]

  const onNominees = (to:string) => {
    navigate(to)
  }

  const onInterview = (to:string) => {
    window.open(to, '_blank');
  }

  const onMouseEvent = (event:string, idx: number) => {
    if(!isMobile){
      if(event === 'hover') {
        setHoverAward(idx+1)
      }else if(event === 'leave'){
        setHoverAward(0)
      }
    }
  }

  useEffect(()=> {
    init();
    for (let i = interviewList.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [interviewList[i], interviewList[j]] = [interviewList[j], interviewList[i]];
    }
    setRandomInterview(interviewList.slice(0,!isMobile?4:3))
  },[])

  useEffect(()=>{
    let sectorDataList:TypeNomineeList[] = []
    if(!episodeInfo.displayAwardee && sectorInfo) {
      sectorInfo.forEach((item, idx)=>{
        if(!item.sectorTitle.includes('특별')){
          sectorDataList.push({
            title: item.sectorTitle.replace('부문',''),
            count: item.list.length,
            to: `/nominees#${idx+1}`
          })
        }
      })
    }else if(episodeInfo.displayAwardee && lastAwardInfo) {
      lastAwardInfo.forEach((item,idx)=>{
        if(!item.sectorTitle.includes('특별')){
          sectorDataList.push({
            title: item.sectorTitle.replace('부문',''),
            count: idx < 2 ? item.list.length + 1 : item.list.length,
            to: `/awards#${idx+1}`,
          })
        }
      })
    }
    setNomineeList(sectorDataList)
    if(sectorInfo){
      let awardList:TypeAwardItem[] = []
      sectorInfo.forEach((item)=>{
        if(!item.sectorTitle.includes('특별')){
          awardList.push({
            title: item.sectorTitle.replace('부문',''),
            list: item.list.map((item)=>item.awardName)
          })
        }
        setAwardList(awardList)
      })
    }
  },[sectorInfo,lastAwardInfo])

  return (
    <S.Home>
      <MainVideo/>
      <div className="wrapper">
        <SplitView
          title='제60회 백상예술대상'
          titleSize={!isMobile?36:22}
          tab={summaryTab}
          onChange={setActiveSum}
          viewMore={{to:'/intro',position:'top'}}
          className={`main-summary ${onInit ? 'fade-in-active':''}`}
        >
          <S.Summary>
            {tabSumContent()}
          </S.Summary>
        </SplitView>
        <SplitView title={`제60회 ${episodeInfo.displayAwardee?'수상':'후보'}`} titleSize={!isMobile?36:22}>
          <S.Nominee>
            {nomineeContent()}
          </S.Nominee>
        </SplitView>
        <S.Banner className="fade-in">
          {
            !isMobile ? (
              <Link to='/history'>
                <img src={HistorBanner} alt="" className="history_pc"/>
              </Link>
            ):(
              <Link to='/history'>
                <div className="mo">
                  <img src={HistorBannerTxt} alt="" />
                </div>
              </Link>
            )
          }
        </S.Banner>
        <S.Interview className="fade-in">
          {
            randomInterview.map((item:TypeInterview)=> {
              return (
                <div className="interview" key={item.text} onClick={()=>{onInterview(item.link)}}>
                  {
                    <ImageRatio src={item.img} ratio={{x:1,y:1}} width={275} hoverEffect={true}/>
                  }
                  <div className="interview-text">
                    <p className="nanum">
                      {item.text}
                    </p>
                  </div>
                  <p className="interview-info">
                    {item.titleInfo}
                  </p>
                </div>
              )
            })
          }
        </S.Interview>
        <SplitView title='역대 수상' titleSize={!isMobile?36:22} onChange={setActiveSum} viewMore={{to:'/winners',position:"bottom"}}>
          <S.AwardHistory>
            {
              randomHistory.map((item:TypeAwardHistory, idx:number)=>{
                return (
                  <Link 
                    to={item.link}
                    className={`history ${hoverAward !== idx+1 ?'':'hover'}`}
                    key={`${item.list[0].sector}_${item.year}`}
                    onMouseEnter={()=>{ onMouseEvent('hover',idx)}}
                    onMouseLeave={()=>{ onMouseEvent('leave',idx)}}
                    onTouchStart={()=>{ setHoverAward(idx+1)}}
                    onTouchEnd={()=>{ setHoverAward(0)}}
                  >
                    <div className="info-wrap">
                      <p className="year nanum">{item.year}</p>
                      <ImageRatio src={item.list[0].img} ratio={{x:5,y:7}} width={!isMobile?98:55}/>
                      <div className="award">
                        <p className="award-title">
                          {item.list[0].winner}
                        </p>
                        {
                          item.list[0].info && (
                            <p className="award-info">
                              {item.list[0].info}
                            </p>
                          )
                        }
                      </div>
                    </div>
                    <IconButtons
                      className="nominee-list-link"
                      size={!isMobile?54:34}
                      icon={{
                        name: 'next',
                        width: !isMobile?18:16,
                        height: !isMobile?18:16,
                      }}
                      onClick={()=>{}}
                      rounded={true}
                      reverse={hoverAward !== idx+1}
                    />
                  </Link>
                )
              })
            }
          </S.AwardHistory>
        </SplitView>
      </div>
      {
        episodeInfo.displayPoll && (
          <S.VoteBanner className="fade-in">
            <Link to='/vote'>
              {!isMobile ? 
                <img src={VOTE_TEXT} alt="프리즘 인기상 투표,4. 25 목요일 오후 12시 KST부터 5. 4 토요일 오후 2시 KST까지" /> :
                <>
                  <img src={VOTE_TEXT_MO1} alt="프리즘 인기상 투표 시작" className="img"/>
                  <img src={VOTE_TEXT_MO2} alt="4. 25 목요일 오후 12시 KST부터 5. 4 토요일 오후 2시 KST까지" className="text" />
                </>
              }
            </Link>
          </S.VoteBanner>
        )
      }
      <S.SwiperSection>
        <SplitView title='Video' titleSize={!isMobile?36:22} viewMore={{to:'/replay',position:"bottom"}}>
          <Swiper
            freeMode={true}
            modules={[Scrollbar, FreeMode, Mousewheel]}
            spaceBetween={20}
            slidesPerView={1.15}
            scrollbar={{ 
              draggable: true 
            }}
            onSlideChange={() => {}}
            mousewheel={{
              invert: false,
              sensitivity: 1, 
              releaseOnEdges: true,
            }}
          >
            {
              mainVideo && mainVideo.map((item:TypeVideo, idx:number)=>{
                return(
                  <SwiperSlide key={idx}>
                    <VideoSlideItem video={item}/>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
        </SplitView>
      </S.SwiperSection>
    </S.Home>
  )
}