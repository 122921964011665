import { useEffect, useState } from "react"
import { Helmet } from 'react-helmet'
import { Outlet } from "react-router-dom"
import { episodeService } from "../../services"
import { EpisodeInfo } from "../../services/models"
import Footer from "../../stories/Footer/Footer"
import Header from "../../stories/Header/Header"
import * as S from './layoutSub.styled'

export default function Layout() {
  const [episodeInfo, setEpisodeInfo] = useState<EpisodeInfo>()
  const init = async () => {
    const resEpisode = await episodeService.getEpisode()
    setEpisodeInfo(resEpisode)
  }
  useEffect(() => {
    init()

    const scrollTop = () =>{
      window.scrollTo({ top: 0 });
    }

    window.addEventListener('beforeunload', scrollTop);
    
    return () => {
      window.removeEventListener('beforeunload', scrollTop);
    };
  }, [])
  return <>
    {
      episodeInfo && (
        <S.Layout>
          <Helmet>
            <meta property="og:title" content={episodeInfo.ogTitle} />
            <meta property="og:image" content={episodeInfo.ogImage} />
            <meta property="og:description" content={episodeInfo.ogDisc} />
          </Helmet>
          <Header episodeInfo={episodeInfo} />
          <Outlet context={{ episodeInfo }} />
          <Footer episodeInfo={episodeInfo} />
        </S.Layout>
      )
    }
  </>
}