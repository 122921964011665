import styled from "styled-components";

export const Vote = styled.div`
  .vote{
    .view-title{
      font-family: Pretendard;
    }
    .left .view-title{
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: -0.75px;
      white-space: pre-line;
      color: ${({ theme }) => theme.colorGray_333};
    }
    .right .nanum{
      font-size: 36px;
      font-weight: 700;
      line-height: 54px;
      letter-spacing: -0.75px;
      
    }
    
    
    ${({ theme }) => theme.media.mobile`${`
      margin-top: 20px;
      .title .view-title{
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        white-space: pre-line;
        color: ${theme.colorGray_333};
      }
      .nanum{
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
      }
    `}`}
  }

  .guide{
    margin-top: 60px;
    border-top: 1px solid ${({ theme }) => theme.colorGray_e1e1e1};

    ${({ theme }) => theme.media.mobile`${`
        border-top: 1px solid ${theme.colorGray_111};
    `}`}

    &-list{
      ul{
        li{
          display: flex;
          gap: 20px;
          align-items: baseline;

          ${({ theme }) => theme.media.mobile`
            flex-direction: column;
            gap: 8px;
          `}

          & + li {
            margin-top: 30px;
          }

          .title{
            flex: 0 0 138px;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: -0.75px;
            color: ${({ theme }) => theme.colorGray_333};
            position: relative;
            padding-left: 14px;

            &::before{
              content: '';
              width: 4px;
              height: 4px;
              position: absolute;
              left: 0;
              top: 10px;
              border-radius: 50%;
              background-color: ${({ theme }) => theme.colorGray_333};
            }
            
            ${({ theme }) => theme.media.mobile`
              flex: 0 0 auto;
              padding-bottom: 0;
              font-size: 16px;
              font-weight: 700;
              line-height: 19px;
              letter-spacing: -0.75px;
              padding-left: 11px;

              &::before{
                width: 3px;
                height: 3px;
                top: 8px;
              }
            `}
          }

          .content{
            font-size: 18px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: -0.75px;
            color: ${({ theme }) => theme.colorGray_333};
            white-space: nowrap;
            ${({ theme }) => theme.media.mobile`
              white-space: wrap;
            `}

            .vote-pc {
              line-height: 28px;
            }

            &.multi {
              line-height: 27px;
              ${({ theme }) => theme.media.mobile`
                line-height: 22.5px;
              `}

              p {
                padding-left: 11px;
                position: relative;
                &::before {
                  content: '';
                  width: 3px;
                  height: 3px;
                  background-color: ${({ theme }) => theme.colorGray_333};
                  border-radius: 50%;
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  ${({ theme }) => theme.media.mobile`
                    top: 12px;
                  `}
                }
              }
            }

            ul.sub-list{
              margin-top: 16px;
              li{
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: -0.75px;
                color: ${({ theme }) => theme.colorGray_8a8a8a};
                position: relative;
                padding-left: 10px;

                & + li{
                  margin-top: 6px;
                }

                &::before{
                  content: '';
                  width: 2px;
                  height: 2px;
                  position: absolute;
                  left: 0;
                  top: calc(50% - 1px);
                  border-radius: 50%;
                  background-color: ${({ theme }) => theme.colorGray_8a8a8a};
                }
              }

              ${({ theme }) => theme.media.mobile`
                margin-top: 10px;
                li {
                  font-size: 13px;
                  line-height: 15.51px;
                  & + li {
                    margin-top: 4px;
                  }

                  &::before{
                    top: 7px;
                  }
                }
              `}
            }

            ${({ theme }) => theme.media.mobile`
              font-size: 15px;
              font-weight: 400;
              line-height: 23px;

              .sub-list li{
                font-size: 13px;
                font-weight: 400;
                line-height: 16px;
              }
            `}
          }
        }
      }
    }
  }
`

export const QrWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .qr {
    ${({ theme }) => theme.media.mobile`
      flex: 1 0 100%;
      display: flex;
      gap: 10px;
      align-items: flex-end;
    `}
  }

  img{
    width: 180px;
    padding: 15px;
    border: 1px solid ${({ theme }) => theme.colorGray_e1e1e1};

    ${({ theme }) => theme.media.mobile`
      width: 144px;
      padding: 12px;
    `}
  }

  .qr-desc{
    margin-top: 16px;
    font-size: 14px;

    .nation{
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      color: ${({ theme }) => theme.colorGray_8a8a8a};
    }
    .text{
      margin-top: 5.5px;
      display: flex;
      gap: 6px;
      justify-content: flex-start;

      span{
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: -0.75px;
        color: ${({ theme }) => theme.colorGray_333};
      }

      ${({ theme }) => theme.media.mobile`
        margin-top: 4px;
        align-items: center;
        span{
          font-size: 15px;
          font-weight: 600;
          line-height: 18px;
        }
      `}
    }
  }
`