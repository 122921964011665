import { useEffect, useState } from "react"
import { episodeService } from "../services"
import { EpisodeInfo } from "../services/models"
import IconButtons from "../stories/Buttons/IconButtons"
import Header from "../stories/Header/Header"
import * as S from "./Error.styled"
import useMobile from '../hooks/useMobile'

export default function Error() {
  let { isMobile } = useMobile()
  const [episodeInfo, setEpisodeInfo] = useState<EpisodeInfo>()
  const init = async () => {
    const resEpisode = await episodeService.getEpisode()
    setEpisodeInfo(resEpisode)
  }
  useEffect(() => {
    init()
  }, [])
  return <>
    {
      episodeInfo && (
        <>
        <Header episodeInfo={episodeInfo} />
        <S.Error>
          <p className="title nanum">
            404 ERROR
          </p>
          <p className="explain">
            잠시 후 서비스를 이용해주시기 바랍니다.<br/>
            시스템 점검으로 서비스 이용에
            {isMobile && <br/>}
            불편을 드려 죄송합니다. 
          </p>
          <div className="reload">
            <span>새로고침</span>
            <IconButtons
              size={!isMobile?30:24}
              icon={{
                name: 'refresh',
                width: !isMobile?16:12.8,
                height: !isMobile?16:12.8,
                fill: '#000000'
              }}
              onClick={()=>{window.location.reload()}}
              rounded={true}
              reverse={true}
            />
          </div>
        </S.Error>
        </>
      )
    }
  </>
}