import { BaseDto } from './base';

export class History extends BaseDto<History> {
  episodeId = '';
  broadDate = '';
  sectorId = '';
  sectorTitle = '';
  image = '';
  imageAlt = '';
  titlePc = '';
  titlePc2 = '';
  titleMo = '';
  titleMo2 = '';

	constructor(partial?: Partial<History>) {
		super();

		Object.assign(this, partial);
	}
}