
import $ from 'jquery';
import { useEffect } from 'react';
import useMobile from '../../hooks/useMobile';
import IconButtons from '../../stories/Buttons/IconButtons';
import { TypeVideo } from './VideoItem';
import * as S from './VideoModal.styled';

export default function VideoModal({
	onClose,
	video,
}: {
	onClose: Function;
	video: TypeVideo;
}) {
	
	useEffect(() => {
		const player = setTimeout(() => {
			// @ts-ignore
			window.$ = $;
			// @ts-ignore
			window.jtbcplayer.getJSON(video.vodId, {
				apiHost: `${process.env.REACT_APP_apiUrl}/`,
				ads: true,
			});
		}, 200)
		return () => clearTimeout(player);
	}, [video]);

  let { isMobile } = useMobile()
	const onModalClose = () => {

		if (
			// @ts-ignore
			typeof window.videojs('player-id') === 'object' &&
			// @ts-ignore
			typeof window.$(window.document) === 'object'
		) {
			// @ts-ignore
			window.videojs('player-id').pause();
			// @ts-ignore
			window.$(window.document).unbind('keydown');
			// // @ts-ignore
			// window.videojs(window.videojs.getPlayers()['player-id'].id()).dispose();
			// @ts-ignore
			delete window.videojs.getPlayers()['player-id'];
		}
		onClose();
	};
	return (
		<S.VideoModal className='video-modal'>
			<div className="contents">
        <IconButtons
          size={!isMobile?54:30}
          icon={{
            name: 'close',
            width: !isMobile?32:18,
            height: !isMobile?32:18
          }}
          onClick={onModalClose}
          rounded={true}
          reverse={true}
          className='close-button'
        />
				<div className='thumbnail'>
					<div id="player-id" data-vod={video.vodId}/>
				</div>
			</div>
		</S.VideoModal>
	);
}
