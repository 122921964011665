import { Link } from 'react-router-dom'
import QR1 from '../assets/images/vote/KOR_qr.svg'
import QR2 from '../assets/images/vote/ENG_qr.svg'
import QR3 from '../assets/images/vote/THAI_qr.svg'
import SplitView from '../components/SplitView'
import useMobile from '../hooks/useMobile'
import IconButtons from '../stories/Buttons/IconButtons'
import * as S from './Vote.styled'

export default function Vote() {
  let { isMobile } = useMobile()

  type TypeQrData = {
    image: string,
    nation: string,
    text: string,
    to: string
  }

  const qrData:TypeQrData[] = [
    {
      image: QR1,
      nation: 'KOR',
      text: '투표하기',
      to: process.env.REACT_APP_qrKOR as string
    },
    {
      image: QR2,
      nation: 'ENG',
      text: 'Vote now',
      to: process.env.REACT_APP_qrENG as string
    },
    {
      image: QR3,
      nation: 'THAI',
      text: 'Vote now',
      to: process.env.REACT_APP_qrIDN as string
    }
  ]

  return (
    <S.Vote>
      <h1>투표하기</h1>
      <SplitView 
        title={`인기상 투표는 제60회 백상예술대상 TV, 영화 부문 
        후보자들을 대상으로 진행되며, 가장 많은 표를
        획득한 남녀 각각 1인이 인기상을 수상합니다.
        `}
        className='vote'
        titleSize={!isMobile?36:14}
      >
        <div className='nanum'>
        특별한 경험이 담긴 리테일 미디어 플랫폼<br/>
        프리즘에서 공식 백상예술대상<br/>
        프리즘 인기상 투표에 참여하세요 
        </div>
      </SplitView>
      <SplitView 
        title="투표 안내"
        className='guide'
      >
        <div className='guide-list'>
          <ul>
            <li>
              <span className='title'>
                투표 기간
              </span>
              <div className='content'>
                4월 25일 (목) 오후 12시 ~ 5월 4일 토요일 오후 2시(KST), 총 10일
              </div>
            </li>
            <li>
              <span className='title'>
                투표 방법
              </span>
              <div className='content multi'>
                {!isMobile?
                  <>
                    <p>프리즘 인기상 투표 페이지에서 1인 1일 4투표 가능 (매일 자정(KST)  갱신)</p>
                    <p>투표 완료 후 인증서 공유 시 추가 2표 지급 (최초 1회 한정)</p>
                  </>
                :
                  <>
                    <p>프리즘 인기상 투표 페이지에서 1인 1일 4투표 가능 (매일 자정(KST)  갱신)</p>
                    <p>투표 완료 후 인증서 공유 시 추가 2표 지급 (최초 1회 한정)</p>
                  </>
                }
              </div>
            </li>
            <li>
              <span className='title'>
                티켓 증정 이벤트
              </span>
              <div className='content'>
                {!isMobile?
                  <p className='vote-pc'>
                    투표에 참여하시면 매일 추첨을 통해 백상예술대상 티켓과 푸짐한 상품을 드립니다.<br/> 자세한 내용은 하단의 QR코드를 통해 확인해 보세요.
                  </p>
                :
                  <>
                    투표에 참여하시면 매일 추첨을 통해 백상예술대상 티켓과 푸짐한 상품을 드립니다. 자세한 내용은 하단의 QR코드를 통해 확인해 보세요.
                  </>
                }
                <ul className='sub-list'>
                  <li>프리즘 인기투표 페이지 {'>'} 백상예술대상 팔로우</li>
                  <li>남자 인기상, 여자 인기상 각 4표 모두 투표 완료</li>
                  <li>익일 오후 4시(KST) 이후 프리즘 공지사항에서 당첨자 확인 가능</li>
                </ul>
              </div>
            </li>
            <li>
              <span className='title'>
                QR코드
              </span>
              <div className='content'>
                <S.QrWrap>
                  {
                    qrData.map((item,idx:number)=>{
                      return (
                        <div className='qr' key={`vote_${idx}`}>
                          <img src={item.image} alt="qr코드" />
                          <div className='qr-desc'>
                            <span className='nation'>
                              {item.nation}
                            </span>
                            <p className='text'>
                              <span>
                                {item.text}
                              </span>
                              <Link to={item.to}>
                                <IconButtons
                                  size={24}
                                  icon={{
                                    name: 'next',
                                    width: 14,
                                    height: 14,
                                  }}
                                  onClick={()=>{}}
                                  rounded={true}
                                />
                              </Link>
                            </p>
                          </div>
                        </div>
                      )
                    })
                  }
                </S.QrWrap>
              </div>
            </li>
          </ul>
        </div>
      </SplitView>
    </S.Vote>
  )
}