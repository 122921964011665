import * as S from './WinnersHistoryTable.styled'
import useMobile from '../../hooks/useMobile'

export type TypeWinnersTable = {
  title: string,
  section: string,
  list: {
    span?: number
    award: string,
    target: string[]
  }[]
}

export default function WinnersHistoryTable({table}:{table:TypeWinnersTable}) {
  let { isMobile } = useMobile()

  return (
    <S.Table className={table.section}>
      <thead>
        <tr>
          <th colSpan={3}>
            {table.title}
          </th>
        </tr>
      </thead>
      <tbody>
        {
          table.list.map((item, idx)=>{
            return !isMobile ? (
              <tr key={idx}>
                {(item.award !== '') && (
                  <td width='33%' className='award-title' rowSpan={item.span?item.span:undefined}>
                    {item.award}
                  </td>
                )}
                <td width='33%'>
                  {item.target[0]}
                </td>
                <td width='33%'>
                  {item.target[1]}
                </td>
              </tr>
            ) : (
              <tr key={idx}>
                {(item.award !== '') && (
                  <td width='100px' className='award-title' rowSpan={item.span?item.span:undefined}>
                    {item.award}
                  </td>
                )}
                <td>
                  {item.target[0]}
                </td>
              </tr>
            )
          })
        }
      </tbody>
    </S.Table>
  )
}