import qs from 'qs';
import { $api } from '../utils/api';
import { SectorInfo, AwardInfo } from './models';

class CandidateService {
  async getSectors() {
    return $api.getAsList('/baeksang/candidate/sector-info', SectorInfo)
  }
  
  async getAwardInfo(awardId: string) {
    let url = '/baeksang/candidate/award-info'
    if (awardId) {
      url = `${url}?${qs.stringify({awardId})}`
    }
    return $api.getAsList(url, AwardInfo)
  }
}

export const candidateService = new CandidateService();

