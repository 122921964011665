import styled from "styled-components";
import VOTE from '../assets/images/home/banner_vote.png'
import HistoryBanner from '../assets/images/home/banner_mo.png'

export const Home = styled.div`
  .wrapper{
    width: 1200px;
    margin: 0 auto;
    ${({ theme }) => theme.media.mobile`
      width: calc(100% - 40px);
    `}
  }

  ${({ theme }) => theme.media.mobile`
    .main-summary {
      margin-top: 53px;
    }
  `}
}
`

export const SummaryDate = styled.div`
  margin-top: -12px;
  ${({ theme }) => theme.media.mobile`
    margin-top: 0;
    padding: 20px 0 10px;
  `}
  .date{
    font-size: 65px;
    font-weight: 700;
    line-height: 90px;
    letter-spacing: -0.75px;
    
    ${({ theme }) => theme.media.mobile`
      font-size: 34px;
      line-height: 48px;    
    `}
  }
  
`

export const SummaryGuideline = styled.div`
  margin-top: -8px;
  ${({ theme }) => theme.media.mobile`
    margin-top: 0;
    padding: 20px 0 10px;
  `}
  .guideline{
    font-size: 52px;
    font-weight: 700;
    line-height: 78px;
    letter-spacing: -0.75px;
    ${({ theme }) => theme.media.mobile`
      font-size: 27px;
      line-height: 41px;    
    `}
  }

`

export const SummaryAward = styled.div`
  ${({ theme }) => theme.media.mobile`
    padding: 20px 0 10px;
  `}
  .award{
    display: flex;
    justify-content;

    ${({ theme }) => theme.media.mobile`
      flex-direction: column;
    `}

    &-list {
      flex: 1 1 33%;

      ${({ theme }) => theme.media.mobile`
        flex: 1 1 auto;
      `}

      & + .award-list {
        border-left: 1px solid ${({ theme }) => theme.colorGray_e1e1e1};
        padding-left: 20px;

        ${({ theme }) => theme.media.mobile`
          border-left: 0;
          padding: 16px 0 0;
          margin-top: 16px;
          border-top: 1px solid #e1e1e1;
        `}

      }

      &-title{
        font-size: 44px;
        font-weight: 600;
        line-height: 51px;
        letter-spacing: -0.75px;
        color: ${({ theme }) => theme.colorGray_111};
        ${({ theme }) => theme.media.mobile`
          font-size: 24px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: -0.75px;
        `}
      }

      ul{
        margin-top: 40px;

        ${({ theme }) => theme.media.mobile`
          margin-top: 16px;
        `}

        li{
          font-size: 18px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: -0.75px;
          position: relative;
          padding-left: 12px;
          color: ${({ theme }) => theme.colorGray_333};

          ${({ theme }) => theme.media.mobile`
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            padding-left: 11px;
          `}

          &::before {
            position: absolute;
            content: '';
            left: 0;
            top: calc(50% - 2px);
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: ${({ theme }) => theme.colorGray_333};

            ${({ theme }) => theme.media.mobile`
              width: 3px;
              height: 3px;
              top: calc(50% - 1.5px);
            `}
          }
          & + li{
            margin-top: 20px;

            ${({ theme }) => theme.media.mobile`
              margin-top: 8px;
            `}
          }
        }
      }
    }
  }
`

export const SummaryDesc = styled.div`
  margin-top: 60px;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.75px;

  ${({ theme }) => theme.media.mobile`
    margin-top: 44px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  `}
`

export const Summary = styled.div`
`

export const Nominee = styled.div`
  .nominee{
    &-list{
      cursor: pointer;
      padding-bottom: 66px;

      ${({ theme }) => theme.media.mobile`
        padding-bottom: 0;
      `}
      
      & + .nominee-list {
        border-top: 1px solid ${({ theme }) => theme.colorGray_111};
        padding-bottom: 66px;
        padding-top: 20px;

        ${({ theme }) => theme.media.mobile`
          margin-top: 30px;
          padding-top: 16px;
          padding-bottom: 0;
        `}
      }

      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 9px;
      
      &-title{
        flex: 0 0 auto;
        font-size: 52px;
        font-weight: 700;
        line-height: 65px;
        letter-spacing: -0.75px;
        color: ${({ theme }) => theme.colorGray_111};

        ${({ theme }) => theme.media.mobile`
          font-size: 30px;
          font-weight: 700;
          line-height: 38px;
        `}
      }
      &-count{
        flex: 1 0 auto;
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0em;
        color: ${({ theme }) => theme.colorGray_8a8a8a};
        margin-top: 5px;

        ${({ theme }) => theme.media.mobile`
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: -0.03em;
          margin-top: 3px;
        `}
      }
      &-link{
        flex: 0 0 auto;
      }
    }
  }
`

export const Banner = styled.div`
  margin-top: 44px;
  .history_pc{
    width: 100%;
  }

  ${({ theme }) => theme.media.mobile`
    margin: 0 auto;
    margin-top: 70px;
    a{
      display: block;
    }
  `}
  .mo{
    background: url(${HistoryBanner});
    padding: 23px 22px;
    background-size: cover;
    background-position: top right;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90.03deg, #000000 20.02%, rgba(0, 0, 0, 0) 99.97%);
    }
    img{
      position: relative;
      z-index: 3;
    }
  }
`

export const Interview = styled.div`
  cursor: pointer;
  margin-top: 110px;
  border-top: 1px solid ${({ theme }) => theme.colorGray_111};

  display: flex;
  justify-content: space-between;
  padding-top: 20px;

  ${({ theme }) => theme.media.mobile`
    margin: 0 auto;
    margin-top: 70px;
    padding-top: 16px;
    flex-direction: column;
    gap: 40px;
  `}

  .interview{
    flex: 0 0 275px;

    ${({ theme }) => theme.media.mobile`
      flex: 0 0 auto;
      .mobile-img{
        width: 100%;
      }
    `}

    & + .interview{
      padding-left: 16px;
      border-left: 1px solid ${({ theme }) => theme.colorGray_e1e1e1};

      ${({ theme }) => theme.media.mobile`
        padding: 0;
        border: 0;
      `}
    }
    &-text{
      margin-top: 28px;
      
      ${({ theme }) => theme.media.mobile`
        margin-top: 16px;
      `}

      p{
        font-size: 30px;
        font-weight: 700;
        line-height: 45px;
        letter-spacing: -0.75px;
        color: ${({ theme }) => theme.colorGray_111};

        ${({ theme }) => theme.media.mobile`
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
        `}
      }

      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical
    }
    &-info{
      margin-toP: 60px;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: -0.75px;
      color: ${({ theme }) => theme.colorGray_8a8a8a};

      ${({ theme }) => theme.media.mobile`
        margin-top: 8px;
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        
      `}
    }
  }
`

export const AwardHistory = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;

  .view-more-history{
    position: absolute;
    bottom: 0;
    left:0;

    a{
      display: flex;
      gap: 6px;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: -0.75px;
      color: ${({ theme }) => theme.colorGray_8a8a8a};
    }
  }

  .history {
    &:not(:first-child){
      border-top: 1px solid ${({ theme }) => theme.colorGray_e1e1e1};
      .award-info{
        color: ${({ theme }) => theme.colorGray_8a8a8a};
      }
    }
    &.hover{
      background: linear-gradient(90deg, #FF4A57 36.5%, #FF9773 71%, #C0FF3E 100%);

      color: ${({ theme }) => theme.colorWhite};
      .award-info{
        color: ${({ theme }) => theme.colorWhite};
      }
      & + .history {
        border-color: #fff;
      }
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 17px;

    .info-wrap{
      display:flex;
      align-items: center;
      ${({ theme }) => theme.media.mobile`
        .image-ratio{
          width: 55px;
          height: 77px;
          img{
            height: 100%;
          }
        }
      `}
    }
    .year{
      font-size: 32px;
      font-weight: 600;
      line-height: 37px;
      letter-spacing: 0em;
      margin-right: 42px;
    }

    .award{
      margin-left: 15px;
      &-title{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.75px;
      }

      &-info{
        margin-top: 8px;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: -0.75px;
      }
    }
  }

  
  ${({ theme }) => theme.media.mobile`
    padding-bottom: 0;
    .history{
      padding: 10px;

      .year{
        margin-right: 12px;
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
      }

      .award{
        margin-right: 12px;

        &-title{
          font-size: 15px;
          font-weight: 600;
          line-height: 18px;
        }

        &-info{
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;

        }
      }
    }

  `}
`

export const VoteBanner = styled.div`
  margin-top: 110px;
  background: url(${VOTE});
  background-size: cover;
  background-position: center;
  padding: 70px 0;
  color: ${({ theme }) => theme.colorWhite};
  height: 399px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
    &.img, &.text{
      display: block;
      text-align: center;
      margin: 0 auto;
      height: auto;
    }
    &.text{
      margin-top: 10px;
    }
  }
  
  ${({ theme }) => theme.media.mobile`
    height: 120px;
    margin-top: 70px;
    padding: 24px 0 25px 0px;

    .title{
      margin: 0;
      &-sub{
        display: none;
      }
      &, & span{
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: -0.03em;
      }
      span{
        margin-top: 5px;
      }
    }

    .date{
      margin-top: 15px;
      gap: 4px;
      span{
        font-size: 10px;
        font-weight: 300;
        line-height: 12px;
        letter-spacing: 0em;
      }
      .dash{
        height: 0.5px;
        width: 8px;
      }
    }
  `}
`

export const SwiperSection = styled.div`
  position: relative;
  overflow: hidden;
  .left{
    flex: 1 0 407px;
    z-index: 3;
    background-color: #fff;
    position: relative;
    &::before{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 100%;
      width: calc(100vw - 600px);
      background-color: #fff;
    }
  }
  .right{
    width: 792px;
    padding-bottom: 120px;
  }
  .swiper{
    overflow: visible;
    &-scrollbar{
      margin-bottom: -66px;
      height: 1px;
      left: 0;
      background-color: ${({ theme }) => theme.colorGray_e1e1e1};
      &-drag{
        background-color ${({ theme }) => theme.colorGray_111};
      }
    }
    
  }
  ${({ theme }) => theme.media.mobile`
    overflow: visible;
    margin: 0 auto;
    width: calc(100% - 40px);
    .swiper{
      width: calc(100% + 20px);
      overflow: hidden;
    }
    &::before{
      content: '';
      position: absolute;
      top: 70px;
      bottom: 0;
      left: -20px;
      width: 20px;
      background-color: #fff;
      z-index: 3;
    }
    .swiper-scrollbar{ 
      display:none
    }
  `}
`