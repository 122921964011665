export class Utils {
	/**
	 * Creates response object
	 * @param type Typescript class type to be returned
	 * @param data Response data
	 * @param useConstructor Boolean (default false). Indicates if we want to use class constructor (true) or use default constructor (false)
	 */
	private __createObject<T>(
		type: any,
		data: any,
		useConstructor = true,
		isNullable = true,
	) {
		let result: T;

		if (!type) {
			return data;
		}
		if (useConstructor) {
			result = new type(data);
			return result;
		}
	}

	/**
	 * Parse response data, before creating response object
	 * @param type Typescript class type to be returned
	 * @param data Response data
	 * @param useConstructor Boolean (default false). Indicates if we want to use class constructor (true) or use default constructor (false)
	 */
	public toClass<T>(
		type: (new (arg: any) => T) | undefined,
		data: any,
		useConstructor = true,
		isNullable = true,
	) {
		if (isNullable && !data) {
			return;
		}

		if (data instanceof Array) {
			return data.map((x) => this.__createObject(type, x, useConstructor));
		} else {
			return this.__createObject(type, data, useConstructor);
		}
	}

	copyToAccountNumber(text: string) {
		const el = document.createElement('input');
		el.addEventListener('focusin', (e) => e.stopPropagation());
		el.value = text;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
	}
}

export const utils = new Utils();
