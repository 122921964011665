import * as S from './ImageRatio.styled'
import useHover from '../../hooks/useHover'

export type TypeImageRatio = {
  src: string,
  width: number,
  ratio?: {
    x: number,
    y: number
  },
  hoverEffect?: boolean
}

export default function ImageRatio ({
  src,
  width,
  ratio={x:1,y:1},
  hoverEffect=false
}:TypeImageRatio) {

  const {
    hover,
    setHover
  } = useHover()

  const onMouseEnter = () => {
    if (!hoverEffect) return
    setHover(true)
  }

  const onMouseLeave = () => {
    if (!hoverEffect) return
    setHover(false)
  }
  return (
    <S.ImageRatio
      $width={width}
      $ratio={ratio}
      $hover={hover}
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
      className='image-ratio'
    >
      <S.Image
        src={src}
        $width={width}
        $ratio={ratio}
      />
    </S.ImageRatio>
  )
}