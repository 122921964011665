import useMobile from "../../hooks/useMobile"
import IconButtons from "../../stories/Buttons/IconButtons"
import * as S from "./MainVideo.styled"

export default function MainVideo() {
  let { isMobile } = useMobile()
  return (
    <S.MainVideo>
      <div className="content">
        <p className="description">
          1965년 시작된 최고 권위의 종합예술상
        </p>
        <div className="title nanum">
          The most prestigious<br/>
          entertainment awards
        </div>
        <hr className="hr"/>
        <div className="scroll-down">
          <IconButtons
            size={33}
            icon={{
              name: 'next',
              width: 16,
              height: 16
            }}
            onClick={()=>{}}
            rounded={true}
          />Scroll down
        </div>
      </div>
      <div className="video">
        <video autoPlay loop playsInline muted>
          <source src={`${process.env.REACT_APP_fileUrl}${process.env[isMobile ? 'REACT_APP_MAIN_VIDEO_MO' : 'REACT_APP_MAIN_VIDEO_PC']}`}  type="video/mp4" />
        </video>
      </div>
    </S.MainVideo>
  )
}