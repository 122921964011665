import { LastAwardInfo } from "@/services/models"
import { useContext, useEffect, useState } from "react"
import SplitView from "../components/SplitView"
import AwardsItem, { TypeAwardsItem } from "../components/awards/AwardsItem"
import { ColorContext } from "../components/layout/layoutSub"
import useColor from "../components/useColor"
import useMobile from '../hooks/useMobile'
import { awardService } from "../services/award"
import { fadeIn } from "../stories/Header/Header"
import Tab, { TabProps } from '../stories/Tab/Tab'
import * as S from './Awards.styled'

export default function Awards() {
  const getColor = useColor;
  let { isMobile } = useMobile()

  const [tabList , setTabList] = useState<TabProps[]>()
  
  const [awardInfo, setAwardInfo] = useState<LastAwardInfo[]>()
  const [activeAwardInfo, setActiveAwardInfo] = useState<LastAwardInfo>()
  const [activeTab, setActiveTab] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [grandAwards, setGrandAwards] = useState<TypeAwardsItem|null>(null)
  const [mainList, setMainList] = useState<TypeAwardsItem[]>()
  const { setColor } = useContext(ColorContext) || {};

  const init = async () => {
    const resAwardList = await awardService.getlastAwardInfo()
    setAwardInfo(resAwardList)
    setTabList(resAwardList.map((item, idx)=>{
      return {
        label: item.sectorTitle,
        value: item.sectorId,
        color: getColor(item.sectorTitle),
        hash: `${idx+1}`
      }
    }))

    if(tabList) {
      setActiveTab(tabList[0].value)
    }
  }

  useEffect(()=>{
    if(tabList && awardInfo) {
      const activeTabData = tabList.filter((item)=>item.value === activeTab)[0]
      const activeData = awardInfo.filter((item)=>item.sectorId === activeTab)[0]

      setTitle(activeTabData.label)
      setActiveAwardInfo(activeData)
    }
    if(activeTab && tabList && setColor){
      const activeData = tabList.filter((tab)=>tab.value === activeTab)[0]
      setColor(activeData.color?.substring(1))
    }
  }, [activeTab])

  useEffect(()=>{
    if(activeAwardInfo){
      if(activeAwardInfo.isExistWinner){
        setGrandAwards({
          image: activeAwardInfo.winner.image,
          target: activeAwardInfo.winner.winnerTitlePc,
          maker: activeAwardInfo.winner.winnerTitlePc2,
          type: 'grand',
          prize: 'Grand Prize',
          subColor: getColor(activeAwardInfo.sectorTitle).substring(1),
          color: getColor(activeAwardInfo.sectorTitle)
        })
      }else{
        setGrandAwards(null)
      }
      setMainList(
        activeAwardInfo.list.map((item)=>{
          return {
            image: item.image,
            target: item.titlePc,
            maker: item.titlePc2,
            type: 'main',
            prize: item.awardName,
            color: getColor(activeAwardInfo.sectorTitle)
          }
        })
      )
      
    }
  }, [activeAwardInfo])

  useEffect(()=>{
    init()
  },[])

  useEffect(()=>{
    fadeIn()
  },[grandAwards,mainList])

  return (
    <S.Awards>
      <h3>제60회</h3>
      <h1>{title} 수상</h1>
      {tabList && <Tab tab={tabList} active={activeTab} onChange={setActiveTab} type='button'/>}
      {grandAwards && (
        <SplitView title={`${title} 대상`} titleSize={!isMobile?36:22} className="awards-section top">
          <S.PrizeSection>
            {grandAwards && (
              <AwardsItem data={grandAwards}/>
            )}
          </S.PrizeSection>
        </SplitView>
      )}
      <SplitView title={`${title} 본상`} titleSize={!isMobile?36:22} className="awards-section">
        <S.PrizeSection>
          {mainList && (
            mainList.map((item:TypeAwardsItem, idx:number)=>{
              return <AwardsItem data={item} key={idx}/>
            })
          )}
        </S.PrizeSection>
      </SplitView>
    </S.Awards>
  )
}