import styled from "styled-components";

export const Video = styled.div`
  cursor: pointer;
  .thumbnail {
    width: 100%;
  }
  .title {
    margin-top: 24px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.75px;
    color: ${({ theme }) => theme.colorGray_111};
    ${({ theme }) => theme.media.mobile`
      margin-top: 12px;
      font-size: 15px;
    `}
  }
`