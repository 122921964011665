import { BaseDto } from './base';

export class NoticeDetail extends BaseDto<NoticeDetail> {
  id = 0
  title = ''
  regDt = ''
  content = ''

	constructor(partial?: Partial<NoticeDetail>) {
		super();

		Object.assign(this, partial);
	}
}

export class NoticeList extends BaseDto<NoticeList> {
  startNo = 0
  list = [
    {
      id: 0,
      title: '',
      regDt: '',
      content: null||'',
      mainContent: ''
    }
  ]

	constructor(partial?: Partial<NoticeList>) {
		super();

		Object.assign(this, partial);
	}
}
