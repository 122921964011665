import qs from 'qs';
import { $api } from '../utils/api';
import { History } from './models';

class HistoryService {
  async getHistoryList() {
    return $api.getAsList('/baeksang/history-awardee', History)
  }
}

export const historyService = new HistoryService();

