
import { useEffect, useState } from 'react'
import { SectorInfo } from "../services/models"
import BANNER from '../assets/images/intro/intro_banner.png'
import SplitView from "../components/SplitView"
import useMobile from '../hooks/useMobile'
import Accordion from "../stories/Accordion/Accordion"
import * as S from './Intro.styled'
import { candidateService } from "../services"

type TypeIntroSection = {
  title: string,
  content: string,
  subTitle: string,
  subContent: string
}

export default function Intro() {
  let { isMobile } = useMobile()
  const [sectorInfo, setSectorInfo] = useState<SectorInfo[]>()
  const [introList, setIntroList] = useState<TypeIntroSection[]>()
  
  const IntroSectionList: TypeIntroSection[] = [
    {
      title: 'TV',
      content: `2023년 4월 1일부터 2024년 3월 31일까지 지상파 종편 케이블 OTT 웹에 제공된 콘텐트로서 
      최소 4부작 이상,연작의 경우 심사일 기준 1/3 이상 방송된 작품이 심사 대상입니다. 신인상은 
      위 작품에서 일정 분량 주·조연급으로 출연한 출연자로서 데뷔연도와 관계없이 해당 작품을 포함, 
      3편 이하의 작품에 출연한 경우 후보 자격을 부여합니다. 예술상은 촬영·미술·음악·음향·의상·분장
      ·시각효과·특수효과 등 기술 관련 스태프를 대상으로 합니다.지난해 후보심사 기간에 맞물려 심사 
      대상에서 제외된 작품은 올해 심사 대상에 포함됩니다.`,
      subTitle: '시상부문',
      subContent: ``
    },
    {
      title: '영화',
      content: `2023년 4월 1일부터 2024년 3월 31일까지 국내에서 개봉 또는 공개된 한국 장편영화가 심사 
      대상입니다.신인상은 위 작품에서 일정 분량 주·조연급으로 출연한 출연자로서 데뷔연도와 
      관계없이 해당 작품을 포함,3편 이하의 작품에 출연한 경우 후보 자격을 부여합니다.신인 감독상은 위 심사 대상에 해당하는 장편영화로 데뷔한 경우 후보 자격을 부여합니다.예술상은 촬영·미술·음악·음향·의상·분장·시각효과·특수효과 등 기술 관련 스태프를 대상으로 합니다.`,
      subTitle: '시상부문',
      subContent: ``
    },
    {
      title: '연극',
      content: `2023년 4월 1일부터 2024년 3월 31일까지 국내에서 공연된 한국 연극이 심사 대상입니다.
      백상연극상은 위 작품 중 가장 뛰어난 연극적 성과를 갖춘 작품 또는 연극인에게 수여합니다.
      작품이 수상하는 경우 작가·연출가·기획자 등 수상작의 예술적 성취에 가장 크게 공헌한 분야의 
      개인을 수상자로 지명하며,개인이 수상하는 경우는 해당연도의 대표작을 명시합니다.
      젊은연극상은 미래지향적이며 창의적인 태도로 연극의 새로운 개념과 형식, 미학적 표현에 
      도전한 단체·작품·개인에게 수여합니다.`,
      subTitle: '시상부문',
      subContent: ``
    }
  ]
  
  const init = async () => {
    const resSectors = await candidateService.getSectors()
    setSectorInfo(resSectors)
    setIntroList(IntroSectionList)
  }

  useEffect(()=>{
    init()
  },[])

  useEffect(()=>{
    if(sectorInfo && introList){
      let introSet:TypeIntroSection[] = []
      sectorInfo.forEach((item, idx)=>{
        if(!item.sectorTitle.includes('특별')){
          introSet.push({
            title: introList[idx].title,
            content: introList[idx].content,
            subTitle: introList[idx].subTitle,
            subContent :`${[0,1].includes(idx) ? '대상 / ':''} ${item.list.map((award)=>award.awardName).join(' / ')}`
          })
        }
        setIntroList(introSet)
      })
    }
  }, [sectorInfo])

  return (
    <S.Intro>
      <h1>
        시상식 소개
      </h1>
      <SplitView 
        title={`본 상은 지난 1년간 방영, 상영 또는 공연된
TV/영화/연극부문의 제작진과 출연자에게 
시상하는 국내 유일의 종합예술상입니다.`}
        titleSize={!isMobile?16:14}
        className='intro'
      >
        <p className="intro-title nanum">
          1965년부터 한국 대중문화 예술의<br/>
          발전과 예술인의 사기진작을 위해<br/>
          제정한 백상예술대상이<br/>
          올해로 60회째를 맞이합니다.
        </p>
      </SplitView>
      <S.IntroBanner className="fade-in">
        <img src={BANNER} alt="백상 예술대상" />
      </S.IntroBanner>
      <SplitView title="시상부문 및 기준" >
        <S.IntroSection>
          {
            introList && introList.map((item:TypeIntroSection,idx:number)=>{
              return(
                <Accordion title={item.title} border={idx>0} key={idx} hoverEffect={false}>
                  <div className="section-content">
                    {item.content}
                  </div>
                  <div className="section-sub">
                    <p className="section-sub-title">
                      {item.subTitle}
                    </p>
                    <p className="section-sub-content">
                      {item.subContent}
                    </p>
                  </div>
                </Accordion>
              )
            })
          }
        </S.IntroSection>
      </SplitView>
    </S.Intro>
  )
}