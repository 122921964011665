import styled from "styled-components";
import { Link } from "react-router-dom";

export const SplitView = styled.div.attrs<{$titleSize:number}>((props)=>({
  $titleSize: props.$titleSize
}))`
  padding-top: 20px;
  width: 1200px;
  margin: 0 auto;
  border-top: 1px solid ${({ theme }) => theme.colorGray_111};
  margin-top: 110px;
  display: flex;
  justify-content: space-between;

  .left{
    flex: 0 1 auto;
    .view-title{
      font-size: ${({$titleSize})=>$titleSize}px
    }
  }
  .right{
    flex: 0 1 792px;
    position: relative;
  }

  .view-more{
    svg{
      fill: #8a8a8a;
    }
  }

  .view-title{
    font-family: 'Nanum Myeongjo';
    font-size: 36px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: -1px;
    margin-bottom: 60px; 
  }
  .tab{
    &-button{
      display: flex;
      align-items: center;
      span{
        font-family: Nanum Myeongjo;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -0.75px;
        flex: 1 0 auto;
        cursor: pointer;
        height:30px;
        line-height: 30px;
      }
      padding: 16px 0;
      gap: 8px;
      &:not(:last-child){
        border-bottom: 1px solid ${({ theme }) => theme.colorGray_e1e1e1};
      }
      &:first-child{
        padding-top:0;
      }
    }
  }
`

export const ViewMore = styled.div.attrs<{$position:'top'|'bottom'}>((props)=>({
  $position: props.$position
}))`
  position: absolute;
  top: ${({$position}) => $position === 'top' ? 0 : 'unset'};
  right: ${({$position}) => $position === 'top' ? 0 : 'unset'};
  bottom: ${({$position}) => $position === 'bottom' ? 0 : 'unset'};
  left: ${({$position}) => $position === 'bottom' ? 0 : 'unset'};

  a{
    display: flex;
    gap: 6px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.75px;
    color: ${({ theme }) => theme.colorGray_8a8a8a};
  }
`

export const MoSplitView = styled.div.attrs<{$titleSize:number}>((props)=>({
  $titleSize: props.$titleSize
}))`
  .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;

    .view-title{
      font-family: 'Nanum Myeongjo';
      font-size: ${({$titleSize})=>$titleSize}px;
      font-size: 22px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: -0.75px;
    }

    a {
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.75px;
      color: ${({ theme }) => theme.colorGray_8a8a8a};
    }
  }

  margin: 70px 0 0;
  padding: 16px 0 0;
  border-top: 1px solid ${({ theme }) => theme.colorGray_111};

  .tab{
    &-view{
      padding: 10px 0 0;
      border-bottom: 1px solid ${({ theme }) => theme.colorGray_e1e1e1};
      &:first-child{
        padding-top: 0;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
    &-button{
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      gap: 6px;
      align-items: center;
      span{
        font-family: 'Nanum Myeongjo';
        font-size: 17px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.75px;
      }
    }
    &-content{
      height: auto;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s;
      border-top: 0;
      
      &.active {
        max-height: 1000px;
        transition: max-height 0.3s;
        border-top: 1px solid ${({ theme }) => theme.colorGray_e1e1e1};
      }
    }
  }
`