import { isMobile } from "react-device-detect";
import { useMediaQuery } from 'react-responsive';

export default function useMobile() {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1200px)'
  })
  return {
    isMobile: isMobile || !isDesktop
  }
}