import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HISTORY_1 from '../assets/images/history/history_1.png';
import HISTORY_2 from '../assets/images/history/history_2.png';
import HISTORY_3 from '../assets/images/history/history_3.png';
import HISTORY_4 from '../assets/images/history/history_4.png';
import HISTORY_5 from '../assets/images/history/history_5.png';
import HISTORY_6 from '../assets/images/history/history_6.png';
import HISTORY_7 from '../assets/images/history/history_7.png';
import useMobile from '../hooks/useMobile';
import IconButtons from "../stories/Buttons/IconButtons";
import ImageRatio from '../stories/Images/ImageRatio';
import * as S from './History.styled';

export default function History() {
  let { isMobile } = useMobile()
  const navigate = useNavigate();

  const onClick = (to:string) =>{
    navigate(to)
  }

  useEffect(() => {
    const handleScroll = () => {

      const lineList = document.getElementsByClassName('history-item');
      Array.from(lineList).forEach((item)=>{

        const target = item as HTMLElement;

        const targetPoint = target.querySelector('.point') as HTMLElement;
        const effectItemImg = target.querySelector('.image-wrap') as HTMLElement;
        const effectItemInfo = target.querySelector('.history-data') as HTMLElement;

        if(targetPoint.offsetTop > 0){
          targetPoint.classList.add('point-active')
          effectItemInfo.classList.add('opacity-active')
          if(!isMobile){
            effectItemImg.classList.add('opacity-active')
          }
        }else{
          targetPoint.classList.remove('point-active')
          effectItemInfo.classList.remove('opacity-active')
          if(!isMobile){
            effectItemImg.classList.remove('opacity-active')
          }
        }
      })
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  const historyData = [
    {
      image: HISTORY_1,
      title: '1960’s',
      text: `1965년 한국 대중문화 예술의 발전과 예술인의 사기진작을 목적으로 
      한국연극영화예술상으로 첫 개최
       영화 <벙어리 삼룡이> 1회 대상 수상`,
      link: '/winners#1965',
    },
    {
      image: HISTORY_2,
      title: '1970’s',
      text: `1974년 TV부문 신설
      1979년 영화, TV, 연극 각 부문별 구분하여 대상 시상
      
      `,
      link: '/winners#1970',
    },
    {
      image: HISTORY_3,
      title: '1980’s',
      text: `한국연극영화예술상 → 한국연극영화TV예술상 → 한국백상예술대상을 거쳐
      1987년 23회부터 백상예술대상으로 개칭
      
      `,
      link: '/winners#1980',
    },
    {
      image: HISTORY_4,
      title: '1990’s',
      text: `기술상, 아역상, 주제가상, 비(非)극영화상, 문화영화상 등 시상부문 다변화 추구


      `,
      link: '/winners#1990',
    },
    {
      image: HISTORY_5,
      title: '2000’s',
      text: `2002년(38회)부터 기존 부문별 수상자들을 제외한 후보자들 중 대상 선정`,
      link: '/winners#2000',
    },
    {
      image: HISTORY_6,
      title: '2010’s',
      text: `2013년(49회) 영화부문 남녀조연상 신설
      2018년(54회) TV부문 남녀조연상, TV/영화부문 각 예술상 신설
      2019년(55회) 연극부문 재신설 `,
      link: '/winners#2010',
    },
    {
      image: HISTORY_7,
      title: '2020’s',
      text: `급변하는 콘텐트 환경속에서 다양한 변화를 시도하며 국내 유일의 종합예술시상식으로 발돋움
      `,
      link: '/winners#2020',
    }
  ]

  return (
    <S.History>
      <S.HistoryHeader>
        <p className="history-since nanum">
          since 1965
        </p>
        <p className="history-title nanum">
          HISTORY OF <br/>
          THE BAEKSANG
        </p>
        <p className="history-desc">
          백상예술대상 개최 60회를 맞아 히스토리를 전해드립니다.
        </p>
      </S.HistoryHeader>
      <div className="history-content">
        {historyData.map((item,idx)=>{
          return !isMobile ? (
            <S.HistoryItem key={idx} className='history-item'>
              <div className="image-wrap">
                <ImageRatio src={item.image} width={518} ratio={{x:259,y:160}} hoverEffect={true}/>
              </div>
              <S.HistoryTimePoint className={`point-line`}>
                <span className={idx+1 === historyData.length ? 'point last-point':'point'}></span>
              </S.HistoryTimePoint>
              <div className="history-data">
                <p className="history-year nanum">
                  {item.title}
                </p>
                <p className="history-desc">
                  {item.text}
                </p>
                <p className="history-link">
                  수상자 보기
                  <IconButtons
                    size={30}
                    icon={{
                      name: 'next',
                      width: 16,
                      height: 16,
                      fill: '#8a8a8a'
                    }}
                    onClick={()=>{onClick(item.link)}}
                    reverse={true}
                    rounded={true}
                  />
                </p>
              </div>
            </S.HistoryItem>
          ):(
            <S.HistoryItem key={idx} className='history-item'>
              <S.HistoryTimePoint className={`point-line`}>
                <span className={idx+1 === historyData.length ? 'point last-point':'point'}></span>
              </S.HistoryTimePoint>
              <div className={`history-data ${idx === 0 ? 'opacity-active':''}`}>
                <ImageRatio src={item.image} width={294} ratio={{x:147,y:91}} hoverEffect={true}/>
                <p className="history-year nanum">
                  {item.title}
                </p>
                <p className="history-desc">
                  {item.text}
                </p>
                <p className="history-link">
                  수상자 보기
                  <IconButtons
                    size={24}
                    icon={{
                      name: 'next',
                      width: 14,
                      height: 14,
                      fill: '#8a8a8a'
                    }}
                    onClick={()=>{onClick(item.link)}}
                    reverse={true}
                    rounded={true}
                  />
                </p>
              </div>
            </S.HistoryItem>
          )
        })}
        <S.HistoryTimeLine className="timeline">
          <span className="timeline-background" />
        </S.HistoryTimeLine>
      </div>
    </S.History>
  )
}