import styled from "styled-components";

export const Table = styled.table`
  flex: 1 1 50%;
  width: 100%;
  max-width: 50%;
  height: fit-content;

  ${({ theme }) => theme.media.mobile`
    flex: 1 1 auto;
    max-width: 100%;
  `}

  text-align: center;

  &.TV{
    th{
      background-color: #06AFDB;
    }
  }

  &.MOVIE{
    th{
      background-color: #FF4A57;
    }
  }
  
  &.THEATER{
    th{
      background-color: #00815D;
    }
  }
  
  &.SPECIAL{
    th{
      background-color: #00815D;
    }
  }
  th{
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -1.2px;

    padding: 13px 0;
    color: ${({theme}) => theme.colorWhite};

    ${({ theme }) => theme.media.mobile`
      font-family: Noto Sans KR;
      font-size: 14px;
      line-height: 22px;
    `}
  }

  tr {
    & + tr{
      border-top: 1px solid ${({theme}) => theme.colorGray_e1e1e1};
    }
    &:last-child {
      border-bottom: 1px solid ${({theme}) => theme.colorGray_e1e1e1};
    }
  }

  td {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.75px;
    height: 50px;
    vertical-align: middle;
    
    &:first-child:not(:last-child) {
      border-right: 1px solid ${({theme}) => theme.colorGray_e1e1e1};
    }
    &:last-child {
      border-left: 1px solid ${({theme}) => theme.colorGray_e1e1e1};
    }

    &.award-title{
      font-weight: 600;
      background-color: #f8f8f8;
    }
    
    ${({ theme }) => theme.media.mobile`
      font-size: 14px;
    `}
  }
`
