import styled from "styled-components";

export const Header = styled.header<{ $scrolled?: boolean; $fixed?: boolean; }>`
  z-index: 100;
  position: ${({ $fixed }) => $fixed ? 'fixed' : 'relative'};
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 140px;
  background-color: ${({ $scrolled, theme }) => $scrolled ? theme.colorWhite : 'transparent'};
  &.history{
    background-color: ${({ theme }) => theme.colorBlack}
  }
  &.white {
    background: transparent;
    &.isTop {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }
  .menus {
    display: flex;
    gap: 24px;
    align-items: center;
    &.white, &.history{
      a{
        color: ${({ theme }) => theme.colorWhite};
        &:hover {
          &:before {
            background-color: ${({ theme }) => theme.colorWhite};
          }
        }
      }
    }
    a {
      position: relative;
      font-size: 1.6rem;
      color: ${({ theme }) => theme.colorGray_111};
      letter-spacing: -0.75px;
      &:hover {
        font-weight: 600;
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: calc(100% + 6px);
          left: 0;
          width: 100%;
          height: 2px;
          background-color: ${({ theme }) => theme.colorGray_111};
        }
      }
    }
  }
`
const MoHeaderHeight = '64px'
export const MoHeader = styled.header<{ $scrolled?: boolean; $fixed?: boolean; $opened: boolean; }>`
  z-index: 100;
  position: ${({ $fixed }) => $fixed ? 'fixed' : 'relative'};
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ $opened }) => $opened ? '100vh' : MoHeaderHeight };
  background-color: ${({ $scrolled, theme }) => $scrolled ? theme.colorWhite : 'transparent'};

  &.history{
    background-color: ${({ theme }) => theme.colorBlack};
    button{
      background-color: transparent;
      svg {
        fill: ${({ theme }) => theme.colorWhite};
      }
    }
  }
  &.white {
    background-color: transparent;
    button{
      background-color: transparent;
      svg {
        fill: ${({ theme }) => theme.colorWhite};
      }
    }
    &.isTop {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    height: ${MoHeaderHeight};
  }
`

export const HeaderFloating = styled.div`
  position: absolute;
  top: ${MoHeaderHeight};
  left: 0;
  width: 100%;
  height: calc(100vh - ${MoHeaderHeight});
  background-color: ${({ theme }) => theme.colorWhite};
  z-index: 9;
  padding: 50px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .menus {
    display: flex;
    flex-direction: column;
    gap: 20px;
    a {
      height: 38px;
      line-height: 38px;
      font-size: 30px;
      font-weight: 700;
      letter-spacing: -0.75px;
      &:hover {
        border-bottom: 2px solid ${({ theme }) => theme.colorGray_111};
      }
    }
  }
  .socials {
    display: flex;
    gap: 8px;
  }
`