import * as S from './VideoSlideItem.styled'
import { TypeVideo } from '../replay/VideoItem'
import VideoModal from '../../components/replay/VideoModal'
import {useState} from 'react'
import { createPortal } from 'react-dom';

export default function VideoSlideItem ({
  video
}:{
  video: TypeVideo
}) {
	const [popup, setPopup] = useState(false);
  return (
    <>
      <S.Video onClick={()=>{setPopup(true)}}>
        <img className='thumbnail' src={video.thumbnail} alt={video.title} />
        <p className='title'>
          {video.title}
        </p>
      </S.Video>
      {popup && createPortal(<VideoModal video={video} onClose={() => setPopup(false)} />,document.body,)}
    </>
  )
}