import {styled, css} from "styled-components";


export const ImageRatio = styled.div.attrs<{$width:number, $ratio:{x:number,y:number}, $hover:boolean}>(props=>({
  $width:props.$width,
  $ratio:props.$ratio,
  $hover:props.$hover,
}))`
  width: ${({$width}) => $width}px;
  height: ${({$width, $ratio}) => ($width/$ratio.x)*$ratio.y}px;
  overflow: hidden;
  
  ${({ theme }) => theme.media.mobile`
    width: 100%;
    height: auto;
  `}
  
  ${({$hover}) => $hover && css`
    img{
      transform: scale(1.15);
      transition: 0.2s;
    }
  `}
`

export const Image = styled.img.attrs<{$width:number, $ratio:{x:number,y:number}}>(props=>({
  $width:props.$width,
  $ratio:props.$ratio,
}))`
  width: ${({$width}) => $width}px;
  height: ${({$width, $ratio}) => ($width/$ratio.x)*$ratio.y}px;
  object-fit: cover;
  object-position: center;
  transition: 0.2s;
  
  ${({ theme }) => theme.media.mobile`
    width: 100%;
    height: auto;
  `}
`