import * as S from './AwardsItem.styled'
import ImageRatio from '../../stories/Images/ImageRatio'

export type TypeAwardsItem = {
  image: string,
  target: string,
  maker: string,
  type: 'grand'|'main',
  prize: string,
  color: string,
  width?: number,
  subColor?: string,
}

export default function AwardsItem ({
  data
}:{
  data:TypeAwardsItem
}) {

  const imageWidth = () => data.type === 'grand' ? 794:183

  return (
    <S.AwardsItem className={`type-${data.type}`} $color={data.color}>
      <ImageRatio src={data.image} width={data.width?data.width:imageWidth()} ratio={{x:5,y:7}} hoverEffect={true}/>
      {data.type === 'grand'?
        <S.GrandPrize $color={data.color} className={`grand sub-${data.subColor}`}>
          <p className='grand-target'>
            <span className='nanum'>{data.target}</span>
          </p>
          <p className='grand-prize'>{data.prize}</p>
        </S.GrandPrize>
        :
        <>
          <p className='prize'>{data.prize}</p>
          <p className='target'>{data.target}</p>
          <p className='maker'>{data.maker}</p>
        </>
      }
    </S.AwardsItem>
  )
}