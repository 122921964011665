import styled from "styled-components";

export const Footer = styled.footer`
  margin-top: 200px;

  position: relative;
  z-index: 99;
  padding: 80px 140px;
  background-color: ${({ theme }) => theme.colorGray_111};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${({ theme }) => theme.media.mobile`
    margin-top: 110px;
    padding: 50px 20px;
    flex-direction: column;
  `}
  .sponsor {
    display: flex;
    flex-direction: column;
    width: 440px;
    height: 134px;
    gap: 14px;
    ${({ theme }) => theme.media.mobile`
      width: 100%;
      height: 74px;
      gap: 10px;
      margin: 40px 0 50px;
    `}
    li {
      display: flex;
    }
    .label {
      display: inline-flex;
      color: rgba(255, 255, 255, 0.50);
      font-size: 1.6rem;
      font-weight: 300;
      letter-spacing: -1px;
      flex: 0 0 100px;
      line-height: 20px;
      ${({ theme }) => theme.media.mobile`
        font-size: 1.3rem;
        letter-spacing: -0.7px;
      `}
    }
    .content {
      flex: 0 0 calc(100% - 100px);
      display: inline-flex;
      gap: 18px;
      flex-wrap: wrap;
      ${({ theme }) => theme.media.mobile`
        gap: 8px 14px;
      `}
      .donjulio {
        margin-bottom: -2px;
      }
    }
  }
  .socials {
    position: absolute;
    left: 140px;
    bottom: 80px;
    display: flex;
    gap: 10px;
    ${({ theme }) => theme.media.mobile`
      position: initial;
      left: 0;
      bottom: 0;
      margin-bottom: 16px;
      img {
        width: 30px;
      }
    `}
  }
  .copyright {
    position: absolute;
    left: 140px;
    top: calc(80px + 29px + 14px);
    color: rgba(255, 255, 255, 0.50);
    font-size: 1.4rem;
    font-weight: 300;
    letter-spacing: -0.28px;
    ${({ theme }) => theme.media.mobile`
      position: initial;
      left: 0;
      top: 0;
      font-size: 1.2rem;
      letter-spacing: -0.24px;
    `}
  }
  .top-button{
    position: absolute;
    top: -70px;
    right: 20px;
    
    ${({ theme }) => theme.media.mobile`
      top: -56px;
    `}
    &.fixed {
      position: fixed;
      top: unset;
      bottom: 20px;
    }
    &.history{
      span{
        color: ${({ theme }) => theme.colorWhite};
      }
    }
  }
`