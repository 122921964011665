import { RouterProvider } from 'react-router-dom';
import { router } from './routes'
import './styles/index.scss'
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA as string);



function App() {
  return <>
    <RouterProvider router={router} />
  </>;
}

export default App;
