import styled from "styled-components";

export const Replay = styled.div`
  .tab-slide{
    margin-top: 60px;
    ${({ theme }) => theme.media.mobile`
      margin-top: 40px;
    `}
  }

  hr.hr{
    margin-top: 100px;
    background-color: ${({ theme }) => theme.colorGray_111};
    ${({ theme }) => theme.media.mobile`
      margin-top: 16px;
      border: 0;
      height: 1px;
    `}
  }

  .replay-title{
    margin-top: 20px;
    font-size: 36px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: -0.75px;

    ${({ theme }) => theme.media.mobile`
      margin-top: 16px;
      font-size: 22px;
      font-weight: 700;
      line-height: 28px;
    `}
  }

  .view-more{
    text-align: center;
  }
`

export const ReplayList = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 40px 21px;
  margin-top: 34px;
  flex-wrap: wrap;

  ${({ theme }) => theme.media.mobile`
    gap: 24px 16px;
  `}
  .video-item {
    flex: 0 1 calc(100%/3 - 42px/3);

    ${({ theme }) => theme.media.mobile`
      gap: 24px 16px;
      flex: 0 1 calc(50% - 16px/2);
    `}
  }
`

export const ViewMore = styled.button`
  margin-top: 90px;
  padding: 10px 16px 10px 20px;
  border: 1px solid ${({ theme }) => theme.colorGray_e1e1e1};
  border-radius: 100px;
  
  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.75px;
    color: ${({ theme }) => theme.colorGray_8a8a8a};
  }
  img{
    margin-left: 2px;
    height: 16px;
    width: 16px;
  }

  ${({ theme }) => theme.media.mobile`
    margin-top: 30px;
    padding: 8px 12px 8px 16px;

    span{
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: -0.75px;
    }
  `}
`