import { BaseDto } from './base';

export class ApiData<T> extends BaseDto<ApiData<T>> {
	code!: number;
	message!: string;
	result: T | any;

	constructor(o: ApiData<T>) {
		super();
		this.code = o.code;
		this.message = o.message;
		this.result = o.result;
	}
}

export class ApiError extends Error {
	code: number;

	constructor(code: number, m: string) {
		super(m);
		this.code = code;

		// Set the prototype explicitly.
		// Object.setPrototypeOf(this, ApiError.prototype)
		// console.error('aaaaaaaaaaaaaaaa eerrorr')
	}

	getMessage() {
		return this.message;
	}
}
