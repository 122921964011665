import qs from 'qs';
import { $api } from '../utils/api';
import { Episode, EpisodeInfo } from './models';

class EpisodeService {
  async getEpisodes() {
    return $api.getAsList('/baeksang/episode', Episode)
    // return '[]'
  }
  async getEpisode(episodeNo?: number) {
    let url = '/baeksang/info'
    if (episodeNo && !isNaN(episodeNo)) {
      url = `${url}?${qs.stringify({episodeNo})}`
    }
    return $api.get(url, EpisodeInfo)
  }
}

export const episodeService = new EpisodeService();

