import { BaseDto } from './base';

export class Video extends BaseDto<Video> {
	bitRate = '';
  broadDate = '';
  clipsNo = '';
  contents = [
    {
      bitRate: '',
      broadDate: '',
      clipsNo: '',
      episodeId: '',
      playtime: '',
      programId: '',
      thumbnail: '',
      vodId: '',
      vodTitle: '',
      vodType: ''
    }
  ];
  currentElements = 0;
  currentPage = 0;
  episodeId = '';
  lastPage = false;
  pageSize = 0;
  playtime = '';
  programId = '';
  thumbnail = '';
  totalElements = 0;
  totalPages = 0;
  vodId = '';
  vodTitle = '';
  vodType = '';

	constructor(partial?: Partial<Video>) {
		super();

		Object.assign(this, partial);
	}
}
