import { ReactElement, ReactNode, useEffect } from 'react'
import * as S from './SplitView.styled'
import IconButtons from "../stories/Buttons/IconButtons"
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useMobile from '../hooks/useMobile'
import { TabProps } from '../stories/Tab/Tab';
import React from 'react';

interface ViewMore {
  to: string;
  position: 'top' | 'bottom';
}

interface SplitViewProps {
  title?: string;
  titleSize?: number;
  tab?: TabProps[];
  children: ReactElement;
  viewMore?: ViewMore;
  fadeIn?: boolean;
  onChange?: (value: string) => void;
  className?: string;
  reverse?: boolean;
  active?: string
}


export default function SplitView ({
  title,
  titleSize,
  tab,
  children,
  viewMore,
  fadeIn=true,
  onChange,
  className='',
  reverse=false,
  active
}:SplitViewProps){
  let { isMobile } = useMobile()
  const [activeTab, setActiveTab] = useState<string|boolean>(tab? tab[0].value : false)
  const [pcHoverTab, setPcHoverTab] = useState<string|boolean>(tab? tab[0].value : false)
  const [hoverTab, setHoverTab] = useState<string|boolean>(tab? tab[0].value : false)

  const onActiveTab = (value:string) => {
    if(isMobile && value === activeTab){
      setActiveTab(false)
      return;
    }
    setActiveTab(value);
    if(onChange)onChange(value);
  }

  useEffect(()=>{
    if(active){
      onActiveTab(active)
    }
  },[active])
  
  const renderPcTab = () => {
    return tab && (
      !reverse ? (
        <div className='tab'>
          {tab.map((tab)=> {
            return(
              <div className="tab-button"
                onClick={()=>{onActiveTab(tab.value)}}
                onMouseEnter={()=>setPcHoverTab(tab.value)}
                onMouseLeave={()=>setPcHoverTab('')}
                key={tab.value}
              >
                <IconButtons
                  size={30}
                  icon={{
                    name: 'next',
                    width: 16,
                    height: 16
                  }}
                  onClick={()=>{}}
                  rounded={true}
                  hoverEffect={true}
                  isHover={activeTab === tab.value || pcHoverTab === tab.value}
                />
                <span>
                  {tab.label}
                </span>
              </div>
            )
          })}
        </div>
      ):(
        <div className='tab'>
          {tab.map((tab)=> {
            return(
              <div className="tab-button"
                onClick={()=>{onActiveTab(tab.value)}}
                onMouseEnter={()=>setHoverTab(tab.value)}
                onMouseLeave={()=>setHoverTab('')}
                key={tab.value}
              >
                <span>
                  {tab.label}
                </span>
                {[hoverTab,activeTab].includes(tab.value) && (
                  <IconButtons
                    size={30}
                    icon={{
                      name: 'next',
                      width: 16,
                      height: 16
                    }}
                    onClick={()=>{}}
                    rounded={true}
                    hoverEffect={true}
                    isHover={activeTab === tab.value}
                    reverse={activeTab !== tab.value}
                  />
                )}
              </div>
            )
          })}
        </div>
      )
    )
  }

  return(
    !isMobile ? (
      <S.SplitView
        $titleSize={titleSize ?? 36}
        key={title}
        className={`${className} fade-in`}
      >
        <div className='left'>
          {title && (
            <p className='view-title'>
              {title}
            </p>
          )}
          {renderPcTab()}
        </div>
        <div className='right'>
          {viewMore && (
            <S.ViewMore $position={viewMore.position}>
              <Link to={viewMore.to}>
                더보기
                <IconButtons
                  size={30}
                  icon={{
                    name: 'next',
                    width: 16,
                    height: 16,
                    fill: '#8a8a8a'
                  }}
                  onClick={()=>{}}
                  rounded={true}
                  className='view-more'
                />
              </Link>
            </S.ViewMore>
          )}
          {children}
        </div>
      </S.SplitView>
    ):(
      <S.MoSplitView
        $titleSize={titleSize ?? 36}
        key={title}
        className={`${className} fade-in`}
      >
        <div>
          {title && (
            <div className='title'>
              <p className='view-title'>
                {title}
              </p>
              { viewMore && (
                <Link to={viewMore.to}>
                  더보기
                </Link>
              )}
            </div>
          )}
          {tab ? (
            <div className='tab'>
              {tab.map((tab, idx)=> {
                return (
                  <div className='tab-view' key={tab.value}>
                    <div className="tab-button"
                      onClick={()=>{onActiveTab(tab.value)}}
                    >
                      <IconButtons
                        size={30}
                        icon={{
                          name: activeTab === tab.value?'close':'plus',
                          width: 16,
                          height: 16
                        }}
                        onClick={()=>{}}
                        rounded={true}
                        isHover={activeTab === tab.value}
                      />
                      <span >
                        {tab.label}
                      </span>
                    </div>
                    <div className={`tab-content ${activeTab === tab.value ? 'active':''}`}>
                      {children && children.props.children[idx]}
                    </div>
                  </div>
                )
              })}
            </div>
          ):(children)}
        </div>
      </S.MoSplitView>
    )
  )
}