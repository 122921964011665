import * as S from './Notice.styled'
import Accordion from '../stories/Accordion/Accordion'
import { noticeService } from '../services/notice'
import { useEffect, useState } from 'react'

type TypeNotice = {
  title: string,
  date: string,
  content: string
}

export default function Notice() {

  const [noticeList, setNoticeList] = useState<TypeNotice[]>()

  const getDetail = async (id:number) => {
    const resDetail = await noticeService.getNoticeDetail(id)
    return resDetail.content
  }

  const init = async () => {
    const resNotice = await noticeService.getNoticeList({page:1, size: 10})
    const noticeListData = await Promise.all(resNotice.list.map(async (item) => {
      const resNoticeDetail = await getDetail(item.id);
      return {
        title: item.title,
        date: item.regDt,
        content: resNoticeDetail,
      };
    }));
  
    setNoticeList(noticeListData);
  }
  useEffect(()=> {
    init()
  },[])

  return (
    <S.Notice>
      <h1>
        공지사항
      </h1>
      <hr className="hr" />
      {
        noticeList && noticeList.map((item, idx) => {
          return (
            <Accordion title={item.title} add={item.date} key={`${item.date}_${idx}`} hoverEffect={false}>
              <div 
                className='notice-content' 
                dangerouslySetInnerHTML={{
                  __html: item.content
                }}>
              </div>
            </Accordion>
          )
        })
      }
    </S.Notice>
  )
}