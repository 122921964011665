import styled from "styled-components";

export const Awards = styled.div`
  .left{
    flex: 0 1 285px;
  }
  .tab-type-button{
    margin-top: 60px;
    ${({ theme }) => theme.media.mobile`
      margin-top: 40px;
    `}
  }
  ${({ theme }) => theme.media.mobile`
    .awards-section {
      margin-top: 60px;
      &.top {
        margin-top: 14px;
      }
    }
  `}
  .type-grand {
    .image-ratio {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
        ${({ theme }) => theme.media.mobile`
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
        `}
      }
    }
  }
`

export const PrizeSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  row-gap: 40px;
  ${({ theme }) => theme.media.mobile`
    gap: 16px;
    row-gap: 24px;
  `}
`