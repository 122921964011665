import qs from 'qs';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApiData, ApiError } from '../services/models';
import { utils } from './utils';

const axiosInstance = axios.create({
	baseURL: '',
	paramsSerializer: (params) => {
		return qs.stringify(params, { allowDots: true });
	},
});

class Api {
	private __packageData(url: string, data?: object) {
		if (!data) {
			return null;
		}
		return data;
	}

	private async __addHeaders(
		config?: AxiosRequestConfig | undefined,
		newBaseURL?: string,
	) {
		config = config || {};
		config.baseURL =
			newBaseURL || process.env.REACT_APP_baseUrl;
		const headers = config.headers || {};
		config.headers = {
			...headers,
			'Content-Type': 'application/json',
		};
		return config;
	}

	private async __error<T>(response: AxiosResponse<ApiData<T>>): Promise<T> {
		return new Promise((resolve, reject) => {
			// console.log('============== ERROR:: ===============', response);
			// if (
			// 	response.data.code === 500 &&
			// 	response.data.message === 'invalid_token'
			// ) {
			// 	code가 500으로 같아도 메시지가 다를 수 있음
			// }
			// console.log(response);
			reject(new ApiError(response.data.code, response.data.message));
		});
	}

	private async __apiResponser<T>(
		response: AxiosResponse<ApiData<T>>,
		type?: new (arg: any) => T,
	): Promise<T> {
		if (response.status === 200) {
			return utils.toClass(type, response.data, true, false) as T;
		}
		return this.__error(response);
	}

	private async __apiListedResponser<T>(
		response: AxiosResponse<ApiData<T>>,
		type?: new (arg: any) => T,
	): Promise<T[]> {
		// console.log(response);
		if (response.status === 200) {
			return utils.toClass(type, response.data, true, false) as T[];
		}
		return this.__error<T[]>(response);
	}

	async get<T>(
		url: string,
		type?: new (arg: any) => T,
		params?: any,
		baseURL?: string,
	): Promise<T> {
		const config = await this.__addHeaders({ params }, baseURL);
		const res = await axiosInstance<ApiData<T>>(url, config);
		const data = await this.__apiResponser(res, type);
		return data;
	}

	async getAsList<T>(
		url: string,
		type?: new (arg: any) => T,
		params?: object | (new (arg: any) => object),
		baseURL?: string,
	): Promise<T[]> {
		const config = await this.__addHeaders({ params }, baseURL);
		const res = await axiosInstance.get<ApiData<T>>(url, config);
		const data = await this.__apiListedResponser(res, type);
		return data;
	}

	async post<T>(
		url: string,
		type?: new (arg: any) => T,
		params?: object | (new (arg: any) => object),
	): Promise<T> {
		const config = await this.__addHeaders();
		const res = await axiosInstance.post<ApiData<T>>(
			url,
			this.__packageData(url, params),
			config,
		);
		const data = await this.__apiResponser(res, type);
		return data;
	}
}

const $api = new Api();

export { $api };
