import * as S from './Header.styled'
import { ReactComponent as ImageLogoHeaderPc } from './assets/LogoHeader__pc.svg'
import { ReactComponent as ImageLogoHeaderPcB } from './assets/LogoHeader__pc_b.svg'
import { ReactComponent as ImageLogoHeaderMo } from './assets/LogoHeader__mo.svg'
import { ReactComponent as ImageLogoHeaderMoB } from './assets/LogoHeader__mo_b.svg'
import ImageInstaWhite from '../../assets/images/sns/insta__white.svg'
import ImageYoutubeWhite from '../../assets/images/sns/youtube__white.svg'
import ImageFacebookWhite from '../../assets/images/sns/facebook__white.svg'
import IconButtons from '../Buttons/IconButtons'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import useMobile from '../../hooks/useMobile'
import { useLocation, useNavigate } from 'react-router-dom'
import { EpisodeInfo } from '../../services/models'
import ReactGA from "react-ga4";


export const fadeIn = () => {
  const fadeInClass = 'fade-in'
  const activeFadeClass = 'fade-in-active'
  const fadeInEl = document.getElementsByClassName(fadeInClass);
  const fadeInDistance = 250;

  Array.from(fadeInEl).forEach((item) => {
    const target = item as HTMLElement;
    const targetOffset = target.offsetTop;
    if (window.scrollY + window.innerHeight > targetOffset + fadeInDistance) {
      if (!item.classList.contains(activeFadeClass)) {
        item.classList.add(activeFadeClass);
      }
    }
  });
};

export default function Header({ episodeInfo }: { episodeInfo: EpisodeInfo }) {
  const navigate = useNavigate()
  let location = useLocation()
  let { isMobile } = useMobile()
  const [scrolled, setScrolled] = useState(true)
  const [opened, setOpened] = useState(false)
  const [white, setWhite] = useState(false)
  const [history, setHistory] = useState(false)
  const [isTop, setIsTop] = useState(false)
  const whiteHeaderPath = ['/', '/nominees','/awards','/history']
  
  useEffect(() => {
    const path = location.pathname
    const displayPath = path.replace(/\/$/, '');

    ReactGA.set({ page: path });
    ReactGA.send("pageview");
    
    window.scrollTo({ top: 0 });
    if(window.scrollY === 0 && path === '/'){
      setIsTop(true)
    }
    
    const accessRules = [
      { targetPath: ['/awards'], rule: 'displayAwardee' },
      { targetPath: ['/nominees', '/candidate'], rule: 'displayCandidate' },
      { targetPath: ['/vote'], rule: 'displayPoll' },
    ];

    accessRules.forEach(({ targetPath, rule }) => {

      if(displayPath.includes('candidate') && episodeInfo.displayCandidate){
        navigate('/nominees', { replace: true });
      }

      if (targetPath.some((item) => displayPath.includes(item)) && !episodeInfo[rule as keyof EpisodeInfo]) {
        navigate('/', { replace: true });
      }
    });
    
    setOpened(false)
    setWhite(whiteHeaderPath.includes(path))
    setHistory(path === '/history')
  
    const handleScroll = () => {
      if(window.scrollY === 0 && path === '/'){
        setIsTop(true)
      }
      if(whiteHeaderPath.includes(path)) {
        setWhite(!(window.scrollY > 0));
      }
      else setWhite(false)
    };

    window.addEventListener('scroll', handleScroll);
		window.addEventListener('scroll', fadeIn);

    fadeIn()
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location, location.pathname, location.hash])

  useEffect(() => {
    fadeIn()
  }, []);

  const menus = [
    {
      label: '히스토리',
      path: '/history',
      id: 'history',
    },
    {
      label: '시상식 소개',
      path: '/intro',
      id: 'intro',
    },
    {
      label: `제${episodeInfo.episodeNo}회 후보`,
      path: '/nominees',
      id: 'nominees',
    },
    {
      label: `제${episodeInfo.episodeNo}회 수상`,
      path: '/awards',
      id: 'awards',
    },
    {
      label: '역대 수상',
      path: '/winners',
      id: 'winners',
    },
    {
      label: '다시보기',
      path: '/replay',
      id: 'replay',
    },
    {
      label: '투표하기',
      path: '/vote',
      id: 'vote',
    },
    {
      label: '공지사항',
      path: '/notice',
      id: 'notice',
    },
  ].filter(({id}) => {
    switch (id) {
      case 'nominees':
        return episodeInfo.displayCandidate
      case 'awards':
        return episodeInfo.displayAwardee
      case 'vote':
        return episodeInfo.displayPoll
      default:
        return true
    }
  })
  const onMenuClick = () => {
    setOpened(!opened)
    setWhite(false)
  }

  const onScrollTop = () => {
    if(location.pathname === '/'){
		  window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  return !isMobile ? (
    (
      <S.Header $scrolled={scrolled} $fixed={true} className={`${white?'white':''} ${history?'history':''} ${isTop && !history?'isTop':''}`}>
        <Link to="/" onClick={onScrollTop}>
          <span className='hidden'>제 {episodeInfo.episodeNo}회 백상예술대상 Baeksang Arts Award</span>
          {white||history ? <ImageLogoHeaderPc width={169} height={61} fill={'#fff'} />:
          <ImageLogoHeaderPcB width={169} height={61} fill={'#111'} />}
        </Link>
        <ul className={`menus ${white?'white':''} ${history?'history':''}`}>
          {
            menus.map((menu) => (
              <li key={JSON.stringify(menu)}>
                <Link to={menu.path}>{menu.label}</Link>
              </li>
            ))
          }
        </ul>
      </S.Header>
    )
  ) : (
      <S.MoHeader $scrolled={scrolled} $fixed={true} $opened={opened} className={`${white?'white':''} ${!opened && history?'history':''} ${isTop && !history?'isTop':''}`}>
        <div className="content">
          <Link to="/" onClick={onScrollTop}>
            <span className='hidden'>제 {episodeInfo.episodeNo}회 백상예술대상 Baeksang Arts Award</span>
            {
              white||(!opened && history) ? 
              <ImageLogoHeaderMo width={202} height={18} fill={'#fff'} />:
              <ImageLogoHeaderMoB width={202} height={18} fill={'#111'} />
            }
          </Link>
          <IconButtons
            size={24}
            icon={{
              name: !opened ? 'menu' : 'close',
              width: 24,
              height: 24,
              fill: white||history?'#fff':'#000'
            }}
            onClick={onMenuClick} rounded={false}
          />
        </div>
        {
          opened && (
            <S.HeaderFloating>
            <ul className={`menus ${white?'white':''} ${history?'history':''}`}>
              {
                menus.map((menu) => (
                  <li key={JSON.stringify(menu)}>
                    <Link className='nanum' to={menu.path}>{menu.label}</Link>
                  </li>
                ))
              }
              </ul>
              <ul className="socials">
                <li>
                  <a href={process.env.REACT_APP_instagram} target="_blank" rel="noreferrer"><img src={ImageInstaWhite} alt="인스타그램 로고 이미지" /></a>
                </li>
                <li>
                  <a href={process.env.REACT_APP_youtube} target="_blank" rel="noreferrer"><img src={ImageYoutubeWhite} alt="유튜브 로고 이미지" /></a>
                </li>
                <li>
                  <a href={process.env.REACT_APP_facebook} target="_blank" rel="noreferrer">
                    <img src={ImageFacebookWhite} alt="페이스북 로고 이미지" />
                  </a>
                </li>
              </ul>
            </S.HeaderFloating>
          )
        }
     </S.MoHeader>
  )
}