import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as S from "./SlideTab.styled";
import { ReactComponent as IconNext } from '../Icons/icons/IconSolidNext.svg'
import IconButtons from '../Buttons/IconButtons'
import { theme } from '../../styles/theme'
import { TabProps } from "./Tab";

export default function Tab({
  tab,
  active,
  onChange,
}: {
  tab: TabProps[];
  active: string;
  onChange: (value: string) => void;
}) {
  const [activeTab, setActiveTab] = useState(active);
	const [prevDisabled, setPrevDisabled] = useState(true);
	const [nextDisabled, setNextDisabled] = useState(false);

  const onActiveTab = (value: string) => {
    setActiveTab(value);
    onChange(value);
    navigate(`${location.pathname}#${value}`, { replace: true });
  };

  const buttonClass = (value: string) =>
    `tab_item_${value} ${activeTab === value ? "active" : ""}`;

  const location = useLocation();
  const navigate = useNavigate();

  const slideWrapper = useRef<HTMLDivElement | null>(null);

  const slideTo = (direction: string) => {
    if (slideWrapper.current) {
      const scrollWidth = slideWrapper.current.scrollWidth;
      const scrollLeft = slideWrapper.current.scrollLeft;
      const elementWidth = slideWrapper.current.clientWidth;

      if (direction === "next") {
        slideWrapper.current.scrollLeft =
          scrollLeft + (elementWidth < scrollWidth ? elementWidth : scrollWidth);
      } else if (direction === "prev") {
        slideWrapper.current.scrollLeft =
          scrollLeft - (elementWidth < scrollLeft ? elementWidth : scrollLeft);
      }
    }
  };

  const setTabPosition = (value:string) => {
    if(slideWrapper.current){
      const activeTab = document.querySelector(`.tab_item_${value}`) as HTMLElement
      console.log(activeTab.offsetLeft, activeTab.scrollLeft)
      slideWrapper.current.scrollLeft = activeTab.offsetLeft - (slideWrapper.current.offsetWidth/2 - 28)
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      if (slideWrapper.current) {
        const endOfScroll =
          slideWrapper.current.scrollWidth - slideWrapper.current.offsetWidth;
  
        setNextDisabled(slideWrapper.current.scrollLeft >= endOfScroll - 10);
        setPrevDisabled(slideWrapper.current.scrollLeft <= 0);
      }
    };

    if (slideWrapper.current) {
      slideWrapper.current.addEventListener('scroll', handleScroll);
      handleScroll();
    }
  
    return () => {
      if (slideWrapper.current) {
        slideWrapper.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [slideWrapper.current]);
  
  useEffect(() => {
    const hash = location.hash.substring(1);
    if (location.hash === "") {
      onActiveTab(tab[0].value);
    } else if (tab.map((item) => item.value).includes(hash)) {
      onActiveTab(hash);
      setTabPosition(hash)
    } else {
      navigate(location.pathname, { replace: true });
    }
  }, [location.hash]);

  return (
    <S.Tab className="tab-slide">
      <IconButtons
        size={36}
        icon={{
          name: 'solidNext',
          width: 24,
          height: 24,
        }}
        onClick={() => slideTo("prev")}
        className={`slide-button prev ${prevDisabled ? "disabled" : ""}`}
      />

      <div ref={slideWrapper} className="slide-wrapper">
        {tab.map((tab) => (
          <S.TabItem
            $color={tab.color?tab.color:'#111'}
            key={tab.value}
            onClick={() => {
              onActiveTab(tab.value);
            }}
            className={buttonClass(tab.value)}
          >
            {tab.label}
          </S.TabItem>
        ))}
      </div>
      <IconButtons
        size={36}
        icon={{
          name: 'solidNext',
          width: 24,
          height: 24,
        }}
        onClick={() => slideTo("next")}
        className={`slide-button next ${nextDisabled ? "disabled" : ""}`}
      />
    </S.Tab>
  );
}
