import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Main = styled.div`
  padding-top: 250px;
  min-height: calc(100vh - 483px);

  ${({ theme }) => theme.media.mobile`
    padding-top: 110px;
    min-height: calc(100vh - 460px);
  `}

  .main{
    position: relative;
    z-index: 3;
    width: 1200px;
    margin: 0 auto;
    ${({ theme }) => theme.media.mobile`
      width: auto;
      padding: 0 20px;
    `}
  }

  position: relative;
  
  &::before{
    content:'';
    position: absolute;
    top: 0;
    width: 100%;
    height: 422px;
    z-index: 0;
    ${({ theme }) => theme.media.mobile`
      height: 184px;
    `}
  }
  &.B_06AFDB { // TV
    &::before{
      background: linear-gradient(180deg, #06AFDB 0%, #fff 75%, rgba(255, 255, 255, 0) 100%);
    }
  }
  &.B_FF4A57 { // MOVIE
    &::before{
      background: linear-gradient(180deg, #FF4A57 0%, #fff 75%, rgba(255, 255, 255, 0) 100%);
    }
  }
  &.B_00815D { // THEATER SPECIAL
    &::before{
      background: linear-gradient(180deg, #00815D 0%, #fff 75%, rgba(255, 255, 255, 0) 100%);
    }
  }
`